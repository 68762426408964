import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SeeAllContainer, SeeAllPageTitle, EndOfListText } from './seeAll.styled';

import { useNavigationLogEvent } from 'globals/hooks/useNavigationLogEvent';
import { SectionMeta } from 'globals/types/meta';

import { TileGridWrapper } from 'components/TileGridWrapper';
import BackToTop from 'components/BackToTop/BackToTop';

const SeeAllPage: React.FC = () => {
  const { t } = useTranslation();

  const location = useLocation();

  // use the state section and avoid calling the same contents again
  const section = location?.state?.section;

  const sectionMeta = section?.metadata as SectionMeta;

  if (sectionMeta?.columns) {
    sectionMeta.columns = [2, 3, 4];
  }

  useNavigationLogEvent('see_all_page', [section]);

  return (
    <SeeAllContainer>
      <SeeAllPageTitle>{t('viewAll:title')}</SeeAllPageTitle>
      <TileGridWrapper section={section}></TileGridWrapper>
      <EndOfListText>{t('viewAll:endOfTheListText')}</EndOfListText>
      <BackToTop />
    </SeeAllContainer>
  );
};

export default SeeAllPage;
