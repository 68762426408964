/* eslint-disable @typescript-eslint/no-explicit-any */
import i18next from 'i18next';

import { IHeroMeta, WatchRelatedProps } from 'globals/types/watch';
import { assetsPath } from '../api';
import { CmsImageRatio, CmsImageSize } from './constants';
import { fetchContentById, getImageWithFallback } from './layoutApi';
import { AerenaContent } from './types';
import { TLanguage } from 'globals/types/language';
import { queryClient } from 'utils/reactQueryConfig';
import { ALL_CONTENT } from 'utils/constants';
import { formatMinuteRuntimeToInfo } from 'utils/runtimeFormat';
import { TSelectedContent } from 'globals/types/playable';

// return image with ratio and size related to a movie content
export const cmsContentImage = (
  imageSize: CmsImageSize,
  imageRatio: CmsImageRatio,
  content: AerenaContent
): string => {
  const regex = new RegExp(imageRatio + '.' + imageSize, 'ig');
  const imageContent: AerenaContent = content?.children.images?.find((image) =>
    regex.test(image.ID)
  );

  return imageContent ? assetsPath + imageContent.children?.main?.[0]?.ID : undefined;
};

//return array of first image of each trailer or undefined
export const movieTrailerImages = (content: AerenaContent): WatchRelatedProps[] => {
  const trailerImages = content?.children?.trailer?.map((trailer) => {
    const id = trailer?.ID;
    const text = (trailer?.metadata as any)?.title;
    const imgRef = trailer?.children?.images?.[0]?.ID;
    const imgSrc = imgRef
      ? assetsPath +
        getImageWithFallback(trailer as AerenaContent, '16:9', 'still', '88rem', content).imageSrc
      : undefined;
    return {
      id,
      imgSrc,
      text
    };
  });
  return trailerImages;
};

export const findObjectById = (value: string, content: any): any => {
  if (!content) {
    return null;
  }

  // If content is an array, recursively search each item
  if (Array.isArray(content)) {
    for (const item of content) {
      const result = findObjectById(value, item);
      if (result) {
        return result;
      }
    }
  } else if (typeof content === 'object') {
    // If content is an object, recursively search its properties
    for (const prop in content) {
      if (prop === 'ID' && content[prop] === value && content['contentTypeID'] !== '!reference') {
        return content;
      }
      const propValue = content[prop];
      const result = findObjectById(value, propValue);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

// meta data of a content
export const getContentMetaData = (content: AerenaContent) => {
  const contentMeta = content?.metadata as any;
  const genres: string[] = contentMeta?.genres as string[];
  const translatedGenres: string[] = contentMeta?.translatedGenres as string[];
  const origin = contentMeta?.origin as string[];
  const originalLanguage = contentMeta?.originalLanguage as string;
  const ratings = contentMeta?.ratings as string[];
  const releaseDate = contentMeta?.releaseDate as string;
  const releaseYear = contentMeta?.releaseYear as string;
  const distributor = contentMeta?.distributor as string;
  const type = content?.contentTypeID;
  const title = contentMeta?.title as string;
  const longDescription = contentMeta?.longDescription as string;
  const synopsis = contentMeta?.synopsis as string;
  const subtitles = contentMeta?.subtitles as string[];
  const audios = contentMeta?.audios as string[];
  const cast = contentMeta?.cast as string;
  const directors = contentMeta?.directors as string;
  const runtime = contentMeta?.runtime;
  const numberOfSeasons = contentMeta?.numberOfSeasons;
  const url = contentMeta?.url;
  return {
    genres,
    translatedGenres,
    releaseYear,
    origin,
    originalLanguage,
    ratings,
    releaseDate,
    distributor,
    title,
    longDescription,
    synopsis,
    runtime,
    type,
    numberOfSeasons,
    subtitles,
    audios,
    url,
    cast,
    directors
  };
};

export const getInformation = (content: AerenaContent, genres: string[], meta: IHeroMeta) => {
  const infoArray = [
    genres?.slice(0, 3)?.join(', '),
    meta?.ratings?.slice(0, 3)?.join(', '),
    meta?.releaseDate || meta?.releaseYear,
    content?.contentTypeID === 'aerena_tv_show'
      ? meta?.numberOfSeasons
      : formatMinuteRuntimeToInfo(meta?.runtime || 0)
  ].filter(Boolean);
  return infoArray.join(' • ');
};

export const getPlayButtonLabel = (
  contentType: string,
  playableMovie: TSelectedContent,
  playableEpisode: TSelectedContent,
  t: any
): string => {
  const contentRemTime =
    contentType === 'aerena_movie'
      ? playableMovie?.remainingTimeInMin
      : playableEpisode?.remainingTimeInMin;
  const playButtonLabel =
    contentRemTime > 0
      ? contentType === 'aerena_movie'
        ? t('watch:playResumeButton')
        : `${t('watch:playResumeButton')} ${
            playableEpisode?.seasonNum && `S${playableEpisode?.seasonNum} `
          }${playableEpisode?.episodeNum && `E${playableEpisode?.episodeNum}`}`
      : contentType === 'aerena_movie'
      ? t('watch:playMovieButton')
      : t('watch:playSeriesButton');
  return playButtonLabel;
};

export const audioLanguages = (content: AerenaContent) => {
  const videoMain = content?.children?.main?.[0];
  return (videoMain?.children?.audio as any[])?.map(
    (audio) => audio.metadata.description as string
  );
};

export const videoContent = (content: AerenaContent) => {
  const videoMain = content?.children?.main?.[0];
  if (videoMain?.contentTypeID === '!reference') {
    const video = findObjectById(videoMain.ID, content);
    return video;
  }
  return videoMain;
};

export const videoPath = (content: AerenaContent) => {
  return videoContent(content)?.children?.manifest?.[0]?.ID;
};

export const relatedContents = (content: AerenaContent, selectedLanguage: TLanguage) => {
  const related = content?.children?.navigation?.[0]?.children?.related;

  if (!related) return undefined;

  const relatedContentRequests = related?.map((item) =>
    fetchContentById(item.ID, { language: selectedLanguage })
  );

  return (
    relatedContentRequests &&
    Promise.all(relatedContentRequests)
      .then((contents) => {
        return contents?.map((relatedMovie) => {
          const title = (relatedMovie?.metadata as any)?.title;
          const imgPath = cmsContentImage(
            CmsImageSize.medium,
            CmsImageRatio.image2_1,
            relatedMovie
          );
          return { imgSrc: imgPath, text: title, id: relatedMovie?.ID };
        });
      })
      .catch((err) => {
        console.error(err);
        return undefined;
      })
  );
};

export const updateRatingValues = (content: AerenaContent) => {
  if (
    'metadata' in content &&
    'ratings' in content.metadata &&
    Array.isArray(content?.metadata?.ratings)
  ) {
    const defaultRating = 'NC-17';
    const ratings = content?.metadata?.ratings?.map((ratingParam) => {
      // Check if rating is an object like { "region": "Germany", "value": "FSK 18" }
      if (typeof ratingParam === 'object' && 'value' in ratingParam) {
        // If rating value was empty, assign default rating value
        return ratingParam.value || defaultRating;
      } else {
        // If rating contains the legacy structure (array of strings)
        // If rating value was empty, assign default rating value
        return ratingParam || defaultRating;
      }
    });
    // Set updated rating value for item.
    // If no rating was found set an array containing default rating value
    content.metadata.ratings = ratings.length ? ratings : [defaultRating];
  }
  return content;
};

export const getCurrentLangBundle = (langCode: string, key: string) => {
  return i18next.getResourceBundle(langCode, key);
};

export const setGenresAudioSubs = (langCode: string) => {
  const allContent: AerenaContent = queryClient.getQueryData([ALL_CONTENT, langCode]);
  const contents = allContent?.children?.contents;

  const activeGenres = new Set<string>();
  const activeAudios = new Set<string>();
  const activeSubs = new Set<string>();

  contents?.forEach((content: AerenaContent) => {
    if (content?.contentTypeID === 'aerena_movie' || content?.contentTypeID === 'aerena_tv_show') {
      const metadata = content?.metadata as any;
      if (metadata) {
        ((metadata.genres as string[]) || []).forEach((genre) => activeGenres.add(genre));
        ((metadata.audios as string[]) || []).forEach((audio) => activeAudios.add(audio));
        ((metadata.subtitles as string[]) || []).forEach((subtitle) => activeSubs.add(subtitle));
      }
    }
  });

  return {
    activeGenres: Array.from(activeGenres),
    activeAudios: Array.from(activeAudios),
    activeSubs: Array.from(activeSubs)
  };
};
