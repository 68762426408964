import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useQueries, useQuery } from '@tanstack/react-query';
import { TIconName } from '@aerq/aerq-design-system';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useShallow } from 'zustand/react/shallow';

import { PathContext } from 'globals/paths';
import { pathToSystemContext } from 'utils/transformations';
import { ISocketEvent } from './types';
import { sdkInstance } from 'globals/api';
import {
  getLanguages,
  getTagIconAndValue,
  fetchContentById,
  getImageWithFallback
} from './cms-helper/layoutApi';
import { useGlobalStore } from './store';
import { AerenaContent } from './cms-helper/types';
import { useNavigationEventStore } from './store/navigationevent.store';
import { TNavigationEventOriginPage } from './types/logevent';
import {
  ACTIVE_LIST,
  ALL_CONTENT,
  MAP_SECTIONS_IDS,
  QUERY_BY_CONTENT_IDS,
  QUERY_BY_PAGE_IDS
} from 'utils/constants';
import { getLocale } from 'utils/switchAppContext';
import i18n from './translations/i18n';
import { useFavoritesStore } from './store/favorites.store';
import { SurveyType, getSurveyById } from 'utils/api/survey';
import { TLanguage } from './types/language';
import { assetsPath } from './api';
import { isAllowedRatingsMatchWithItem } from './cms-helper/contentHelper';
import { defaultLanguage } from './store/global.store';
import { useSeatNumber } from './hooks/useSeatNumber';
import { getEnvValue } from 'utils/useConfig';
import { socketPSS } from 'utils/socket';
import { setGenresAudioSubs } from './cms-helper/movieHelper';
import { TAppTileItem, TileItem, TViewType } from './types/tile';
import { SectionMeta } from './types/meta';

export const useHandlePathContext = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    let pathChangeTimer: NodeJS.Timeout;
    const setSystemContext = (context: PathContext) => {
      //do not change the context that fast. there are redirects etc.
      pathChangeTimer = setTimeout(() => {
        sdkInstance?.setContext(context);
      }, 100);
    };

    const pathContext = pathToSystemContext(pathname);
    if (typeof window?.SystemUIClient !== 'undefined') {
      setSystemContext(pathContext);
    } else {
      window.addEventListener('systemUiReady', () => setSystemContext(pathContext));
    }

    return () => {
      clearTimeout(pathChangeTimer);
      window.removeEventListener('systemUiReady', () => setSystemContext(pathContext));
    };
  }, [pathname]);
};

export const useScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const timeout = setTimeout(
      () =>
        window.scrollTo({
          top: 0,
          behavior: 'instant'
        }),
      0
    );
    return () => clearTimeout(timeout);
  }, [pathname]);
};

export const useDisableBodyScroll = (val: boolean) => {
  useEffect(() => {
    if (val) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [val]);
};

export const useFilterContentByAudioSubtitleRating = (
  data: AerenaContent[],
  selectedAudios: string[],
  selectedSubtitles: string[],
  allowedRatings: string[]
) => {
  return useMemo(() => {
    const filteredData = data?.filter((item) => {
      const itemAudios = ((item?.metadata as any)?.audios as string[]) ?? [];
      const itemSubtitles = ((item?.metadata as any)?.subtitles as string[]) ?? [];
      const itemMeta = item?.metadata as SectionMeta;

      const hasSelectedAudios =
        selectedAudios.length === 0 ||
        itemAudios?.length === 0 ||
        itemAudios?.some((audio) => selectedAudios.includes(audio));

      const hasSelectedSubtitles =
        selectedSubtitles.length === 0 ||
        itemSubtitles?.length === 0 ||
        itemSubtitles?.some((subtitle) => selectedSubtitles.includes(subtitle));

      const hasAllowedRating = isAllowedRatingsMatchWithItem(itemMeta, allowedRatings);

      return hasSelectedAudios && hasSelectedSubtitles && hasAllowedRating;
    });
    return filteredData;
  }, [data, selectedAudios, selectedSubtitles, allowedRatings]);
};

export const useMediaRestrictionListener = () => {
  const { setMediaRestriction } = useGlobalStore(
    useShallow((state) => ({
      setMediaRestriction: state.setMediaRestriction
    }))
  );
  const seatNumber = useSeatNumber();

  useEffect(() => {
    socketPSS.connect();

    socketPSS.emit('MEDIA_RESTRICTION_STATE', {
      jsonrpc: '2.0',
      method: 'getMediaRestrictions',
      params: { seatId: seatNumber }
    });

    const onMediaRestrictionReceived = async (payload: ISocketEvent) => {
      setMediaRestriction(payload?.params?.allowedRatings);

      socketPSS.emit('ACKNOWLEDGMENT', {
        jsonrpc: '2.0',
        method: 'acknowledgment',
        id: uuidv4(),
        params: {
          timestamp: Date.now()
        },
        correlationId: payload?.id
      });
      // Navigation is commented as it interrupts the player and changes the System UI
      // if (!['/welcome', '/onboarding'].includes(window.location.pathname)) {
      //   navigate('/');
      // }
    };

    socketPSS.on('MEDIA_RESTRICTION_STATE', onMediaRestrictionReceived);

    return () => {
      socketPSS.off('MEDIA_RESTRICTION_STATE', onMediaRestrictionReceived);
      socketPSS.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useBillboardInView = (options: IntersectionObserverInit, data: AerenaContent) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [elementViewed, setElementViewed] = useState<boolean>(false);
  const [impressionWaiting, setImpressionWaiting] = useState(false);

  const sendElementViewConfirm = useCallback(
    async (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && !elementViewed) {
        const metadata: any = data?.metadata;
        try {
          if (!impressionWaiting) {
            setImpressionWaiting(true);
            const res: any = await axios.get(`/a3/api/view/impression?id=${metadata?.productId}`);
            setImpressionWaiting(false);
            if (res?.data?.productId) {
              setElementViewed(entry.isIntersecting);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [elementViewed, data?.metadata, impressionWaiting]
  );

  useEffect(() => {
    const currentRef = containerRef.current;
    const observer = new IntersectionObserver(sendElementViewConfirm, options);

    if (currentRef) {
      observer.observe(currentRef);
    }

    if (currentRef && elementViewed) {
      observer.unobserve(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [containerRef, elementViewed, options, sendElementViewConfirm]);

  return { containerRef };
};

export const useGetFilteredGridContents = (data: AerenaContent[], skip: number, limit: number) => {
  const [tileItems, setTileItems] = useState<TileItem[]>(undefined);
  const [totalItemsLen, setTotalItemsLen] = useState<number>(0);

  const { selectedAudios, selectedSubtitles, allowedRatings, selectedLanguage } = useGlobalStore(
    useShallow((state) => ({
      selectedAudios: state.selectedAudios,
      selectedSubtitles: state.selectedSubtitles,
      allowedRatings: state.allowedRatings,
      selectedLanguage: state.selectedLanguage
    }))
  );

  const filteredMedia = useFilterContentByAudioSubtitleRating(
    data,
    selectedAudios,
    selectedSubtitles,
    allowedRatings
  );

  useEffect(() => {
    if (!filteredMedia) {
      return;
    }
    if (filteredMedia?.length > 0) {
      const itemsWithTileData: TileItem[] = filteredMedia?.map(
        (item: AerenaContent, index: number) => {
          const itemMeta = item?.metadata as Record<string, any>;

          const { imageSrc, dominantColor } = getImageWithFallback(
            item,
            '3:4',
            'poster',
            '37.2rem'
          );

          const imgSrc = assetsPath + imageSrc;

          const tileItem: TileItem = {
            ID: item?.ID,
            title: itemMeta?.title,
            contentTypeID: item?.contentTypeID,
            url: itemMeta?.url as string,
            imageSrc: imgSrc,
            dominantColor: dominantColor,
            rankNo: index + 1,
            appId: itemMeta?.['app-ID'],
            context: itemMeta?.context
          };
          return tileItem;
        }
      );
      setTotalItemsLen(itemsWithTileData?.length);
      setTileItems(itemsWithTileData?.filter(Boolean)?.slice(0, limit * skip) || []);
    } else {
      setTotalItemsLen(0);
      setTileItems([]);
    }
  }, [filteredMedia, selectedLanguage.code, skip, limit]);

  return { tileItems, totalItemsLen };
};

export const useGetSwimlaneContents = (section: AerenaContent, viewType?: TViewType) => {
  const [tileItems, setTileItems] = useState<TileItem[]>(undefined);

  const {
    selectedAudios,
    selectedSubtitles,
    allowedRatings,
    selectedLanguage,
    currentPodcastPlayed,
    aerenaStudioPreview
  } = useGlobalStore(
    useShallow((state) => ({
      selectedAudios: state.selectedAudios,
      selectedSubtitles: state.selectedSubtitles,
      allowedRatings: state.allowedRatings,
      currentPodcastPlayed: state.currentPodcastPlayed,
      selectedLanguage: state.selectedLanguage,
      aerenaStudioPreview: state.aerenaStudioPreview
    }))
  );

  const { previewDate } = aerenaStudioPreview;

  const { data: allContent } = useQuery({
    queryKey: [ALL_CONTENT, selectedLanguage.code],
    queryFn: () =>
      fetchContentById(ALL_CONTENT, { language: selectedLanguage, depth: 1, previewDate })
  });

  const swimLaneContents = useMemo(() => {
    return section?.children?.filter?.[0]?.children?.contents
      ?.map((i) => allContent?.children?.contents?.find((content) => content?.ID === i?.ID))
      ?.filter(Boolean);
  }, [allContent?.children?.contents, section?.children?.filter]);

  const filteredMedia = useFilterContentByAudioSubtitleRating(
    swimLaneContents,
    selectedAudios,
    selectedSubtitles,
    allowedRatings
  );

  const createTileItem = useCallback(
    (item: AerenaContent, index: number, sectionMeta: any, assetsPath: string) => {
      const itemMeta = item?.metadata as Record<string, any>;
      const { imageSrc, dominantColor, imageAspectRatio } =
        item?.contentTypeID === 'aerena_podcast'
          ? getImageWithFallback(item, '1:1', 'poster', '49.6rem')
          : getImageWithFallback(
              item,
              sectionMeta?.tileRatio,
              sectionMeta?.imageType,
              sectionMeta?.tileWidth?.[2]
            );

      const getTileMetaData = (sectionMeta: any, item: AerenaContent) => [
        sectionMeta?.tileTitle?.[item?.contentTypeID]?.[0],
        sectionMeta?.tileCaption?.[item?.contentTypeID]?.[0],
        sectionMeta?.tileDescription?.[item?.contentTypeID]?.[0],
        sectionMeta?.tileSub?.[item?.contentTypeID]?.[0]
      ];

      const [tileTitle, tileCaption, tileDescription, tileSub] = getTileMetaData(sectionMeta, item);

      const imgSrc = assetsPath + imageSrc;
      const { tagValue: tileTagValue, tagIcon: tileTagIcon } = getTagIconAndValue(
        item?.contentTypeID
      );

      const tileItem: TileItem = {
        ID: item?.ID,
        contentTypeID: item?.contentTypeID,
        title: itemMeta?.[tileTitle],
        description: itemMeta?.[tileDescription],
        caption: itemMeta?.[tileCaption],
        sub: itemMeta?.[tileSub],
        tag: tileTagValue,
        icon: tileTagIcon as TIconName,
        url: itemMeta?.url as string,
        imageSrc: imgSrc,
        dominantColor: dominantColor,
        imageAspectRatio,
        rankNo: index + 1,
        appId: itemMeta?.['app-ID'],
        context: itemMeta?.context
      };

      const shouldHideTile = (tileItem: TAppTileItem, item: AerenaContent) => {
        return (
          (['aerena_game', 'aerena_offer', 'aerena_what_to_do'].includes(item?.contentTypeID) &&
            (!tileItem?.url || tileItem?.url === '#')) ||
          (item?.contentTypeID === 'aerena_application' && !tileItem?.appId)
        );
      };

      if (item?.contentTypeID === 'aerena_podcast') {
        return createPodcastTileItem(
          tileItem,
          itemMeta,
          item,
          sectionMeta,
          currentPodcastPlayed,
          tileCaption
        );
      } else if (shouldHideTile(tileItem, item)) {
        return null;
      }
      return tileItem;
    },
    [currentPodcastPlayed]
  );

  const createPodcastTileItem = (
    tileItem: TileItem,
    itemMeta: any,
    item: AerenaContent,
    sectionMeta: any,
    currentPodcastPlayed: string,
    tileCaption: string
  ) => {
    const { artist, numberOfEpisodes, releaseYear, title } = itemMeta || {};

    const podcastTileItem: TileItem = {
      ...tileItem,
      artist: Array.isArray(artist) ? artist?.join(', ') : artist,
      numberOfEpisodes,
      releaseYear,
      title,
      description: Array.isArray(artist) ? artist?.join(', ') : artist
    };
    if (tileCaption === 'numberOfEpisodes') {
      const infos = [
        `${numberOfEpisodes} ${i18n.t(['listen:details.episodes.title'])}`,
        releaseYear && `${releaseYear}`
      ]
        .filter(Boolean)
        .join(' • ');

      podcastTileItem.caption = infos;
    }
    if (
      currentPodcastPlayed?.toLowerCase() === item?.ID?.toLowerCase() &&
      sectionMeta?.type === 'TileScroller'
    ) {
      podcastTileItem.isHighlighted = true;
    }
    return sectionMeta ? podcastTileItem : null;
  };

  useEffect(() => {
    if (filteredMedia?.length > 0) {
      const sectionMeta = section?.metadata as SectionMeta;
      let viewableMedia: AerenaContent[] = [...filteredMedia];
      if (sectionMeta && viewType !== 'Grid') {
        if (sectionMeta?.hasSeeAll) {
          viewableMedia = filteredMedia?.slice(0, (sectionMeta?.seeAllMaxLimit || 9) + 1);
        } else {
          viewableMedia = filteredMedia?.slice(0, 10);
        }
      }

      const itemsWithTileData: TileItem[] = viewableMedia?.map(
        (item: AerenaContent, index: number) => {
          const tileItem = createTileItem(item, index, sectionMeta, assetsPath);

          return sectionMeta && tileItem ? tileItem : null;
        }
      );
      setTileItems(itemsWithTileData?.filter(Boolean) || []);
    } else {
      setTileItems([]);
    }
  }, [currentPodcastPlayed, filteredMedia, viewType, section?.metadata, createTileItem]);

  return { tileItems };
};

export const useTileClickHandler = (section?: AerenaContent) => {
  const navigate = useNavigate();

  const { selectedLanguage, setResetWatchOptions } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage,
      setResetWatchOptions: state.setResetWatchOptions
    }))
  );

  const setNavigationEventOriginPage = useNavigationEventStore(
    (state) => state.setNavigationEventOriginPage
  );

  const appClickHandler = useCallback(
    async (tile: TAppTileItem) => {
      if (tile.appId) {
        if (tile.appId.includes('ejournal')) {
          navigate(tile.appId);
          return;
        }
        if (tile.appId.includes('survey')) {
          const { ID: surveyId } = await getSurveyById(SurveyType.fullBlown);
          sdkInstance.startApp(tile.appId, `/${surveyId}`);
          return;
        }
        if (tile.ID === 'OnboardBar frontend') {
          sdkInstance.startApp(tile.appId, '/bar');
          return;
        } else if (tile.ID === 'OnboardShop frontend') {
          sdkInstance.startApp(tile.appId, '/shop');
          return;
        }
        sdkInstance.startApp(tile.appId, '');
      }
    },
    [navigate]
  );

  return useCallback(
    async (tile: TileItem) => {
      if (tile.url === 'WifiPairing') {
        sdkInstance?.startPedHandoff();
        return;
      }
      if (tile.url === 'onboarding-survey') {
        try {
          const REACT_APP_SURVEY_URL = getEnvValue('REACT_APP_SURVEY_URL');
          const { ID: surveyId } = await getSurveyById(SurveyType.onboarding);
          const redirectUrl = `${REACT_APP_SURVEY_URL}/${surveyId}`;
          sdkInstance?.setContext('onboarding');
          setNavigationEventOriginPage({
            type: 'onBoardingPage',
            languageSelected: selectedLanguage.code,
            clickedElement: {
              id: tile?.ID,
              rank: tile?.rankNo,
              name: tile?.title,
              type: tile?.contentTypeID
            }
          });
          window.location.replace(redirectUrl);
          return;
        } catch (error) {
          console.error(error);
          return;
        }
      }
      if (tile?.url?.includes('about')) {
        navigate('about', { replace: true });
        return;
      }

      if (section) {
        const sectionMeta = section?.metadata as SectionMeta;
        const state: TNavigationEventOriginPage = {
          type: sectionMeta?.categories?.[0],
          languageSelected: selectedLanguage.code,
          clickedElement: {
            id: section?.ID,
            name: sectionMeta?.title,
            type: sectionMeta?.type,
            rank: sectionMeta?.rank + 1,
            clickedElement: {
              id: tile?.ID,
              name: tile?.title,
              type: tile?.contentTypeID,
              rank: tile?.rankNo
            }
          }
        };
        setNavigationEventOriginPage(state);
      }

      if (tile?.url) {
        if (tile?.url?.includes('watch')) setResetWatchOptions(true);
        navigate(tile.url, { replace: true });
        return;
      }
      const url = getTileURL(tile);
      if (url) {
        navigate(url, {
          state: {
            type: tile?.contentTypeID // To update the content type in watchdetails page
          }
        });
        return;
      }
      if ('appId' in tile) {
        appClickHandler(tile);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appClickHandler]
  );
};

export const getTileURL = (tile: TileItem) => {
  for (const page in MAP_SECTIONS_IDS) {
    if (MAP_SECTIONS_IDS[page].includes(tile?.contentTypeID)) return `/${page}/${tile?.ID}`;
  }
};

export const getPageID = (pageId: string) => {
  const previewID = useGlobalStore.getState().aerenaStudioPreview.previewID;

  return previewID ? `preview_${previewID}_${pageId}` : pageId;
};

export const usePrefetchContents = (isStartup: boolean, previewID: string, previewDate: string) => {
  const langContent = useRef(null);
  const allContents = useRef(null);

  // prefetch the active_languages query to decide whether to continue the api calls
  langContent.current = useQuery<TLanguage[]>({
    queryKey: [QUERY_BY_CONTENT_IDS.ACTIVE_LANGS],
    queryFn: () => getLanguages(QUERY_BY_CONTENT_IDS.ACTIVE_LANGS),
    enabled: isStartup,
    throwOnError: true
  });

  // prefetch the  contents
  allContents.current = useQueries({
    queries:
      langContent.current?.data?.length > 0
        ? langContent.current?.data?.map((language: TLanguage) => {
            return {
              queryKey: [ALL_CONTENT, language.code],
              queryFn: () => fetchContentById(ALL_CONTENT, { language, depth: 1, previewDate }),
              enabled: (langContent.current?.data?.length > 0 && isStartup) || !!previewID,
              throwOnError: true
            };
          })
        : []
  });

  // prefetch the active genres, audios and subs
  useQuery({
    queryKey: [ACTIVE_LIST],
    queryFn: () => setGenresAudioSubs('de'), // could be any lang to get the keys
    enabled: Boolean(allContents.current?.[0]?.data)
  });

  // prefetch query for home page
  const homepageID = getPageID(QUERY_BY_PAGE_IDS.HOME);
  useQueries({
    queries:
      langContent.current?.data?.length > 0
        ? langContent.current?.data?.map((language: TLanguage) => {
            return {
              queryKey: [homepageID, language.code],
              queryFn: () =>
                fetchContentById(homepageID, {
                  language,
                  depth: 2
                }),
              enabled: langContent.current?.data?.length > 0 && isStartup && !previewID,
              throwOnError: isStartup
            };
          })
        : []
  });

  // prefetch query for watch page
  const watchPageID = getPageID(QUERY_BY_PAGE_IDS.WATCH);
  useQueries({
    queries:
      langContent.current?.data?.length > 0
        ? langContent.current?.data?.map((language: TLanguage) => {
            return {
              queryKey: [watchPageID, language.code],
              queryFn: () =>
                fetchContentById(watchPageID, {
                  language,
                  depth: 2
                }),
              enabled: langContent.current?.data?.length > 0 && isStartup && !previewID,
              throwOnError: isStartup
            };
          })
        : []
  });

  // prefetch query for listen page
  const listenPageID = getPageID(QUERY_BY_PAGE_IDS.LISTEN);
  useQueries({
    queries:
      langContent.current?.data?.length > 0
        ? langContent.current?.data?.map((language: TLanguage) => {
            return {
              queryKey: [listenPageID, language.code],
              queryFn: () =>
                fetchContentById(listenPageID, {
                  language,
                  depth: 2
                }),
              enabled: langContent.current?.data?.length > 0 && isStartup && !previewID,
              throwOnError: isStartup
            };
          })
        : []
  });
  const aboutPageID = getPageID(QUERY_BY_PAGE_IDS.ABOUT);
  useQueries({
    queries:
      langContent.current?.data?.length > 0
        ? langContent.current?.data?.map((language: TLanguage) => {
            return {
              queryKey: [aboutPageID, language.code],
              queryFn: () =>
                fetchContentById(aboutPageID, {
                  language
                }),
              enabled: langContent.current?.data?.length > 0 && isStartup && !previewID
            };
          })
        : []
  });
};

export const useSystemSelectedLanguage = () => {
  const { setSelectedLanguage, setIsLanguageUpdated, aerenaStudioPreview } = useGlobalStore(
    useShallow((state) => ({
      setSelectedLanguage: state.setSelectedLanguage,
      setIsLanguageUpdated: state.setIsLanguageUpdated,
      aerenaStudioPreview: state.aerenaStudioPreview
    }))
  );

  const { data: languages } = useQuery({
    queryKey: [QUERY_BY_CONTENT_IDS.ACTIVE_LANGS],
    queryFn: () => getLanguages(QUERY_BY_CONTENT_IDS.ACTIVE_LANGS),
    throwOnError: true
  });

  useEffect(() => {
    const { previewID } = aerenaStudioPreview;
    if (!previewID && languages) {
      const code = getLocale();
      const selectedLanguage = languages?.find((lang) => lang?.code === code) || defaultLanguage;
      setSelectedLanguage(selectedLanguage);
      setIsLanguageUpdated(true);
      i18n.changeLanguage(selectedLanguage.code);
    } else if (previewID) {
      setIsLanguageUpdated(true);
    }
  }, [aerenaStudioPreview, languages, setIsLanguageUpdated, setSelectedLanguage]);
};

export function useFavorites(favoriteType: string) {
  const { favorites, setFavorites } = useFavoritesStore(
    useShallow((state) => ({
      favorites: favoriteType === 'watch' ? state.watchFavorites : state.listenFavorites,
      setFavorites: favoriteType === 'watch' ? state.setWatchFavorites : state.setListenFavorites
    }))
  );

  const addFavorite = useCallback(
    (content: AerenaContent) => {
      const newFavorite = {
        id: content?.ID,
        type: content?.contentTypeID,
        url: getTileURL({ ID: content?.ID, contentTypeID: content?.contentTypeID })
      };
      setFavorites((prev) => [newFavorite, ...prev]);
    },
    [setFavorites]
  );

  const removeFavorite = useCallback(
    (id: string) => {
      setFavorites((prev) => prev.filter((item) => item.id !== id));
    },
    [setFavorites]
  );

  const toggleFavorite = useCallback(
    (content: AerenaContent) => {
      const isFavorite = favorites.find((item) => item.id === content?.ID);
      if (isFavorite) {
        removeFavorite(content?.ID);
      } else {
        addFavorite(content);
      }
    },
    [favorites, addFavorite, removeFavorite]
  );

  return { toggleFavorite, isFavorite: (id: string) => favorites.some((item) => item.id === id) };
}

export const useThemeContent = () => {
  const themeId = getPageID(QUERY_BY_CONTENT_IDS.THEME);
  const { data } = useQuery({
    queryKey: [themeId],
    queryFn: () => fetchContentById(themeId)
  });
  const logoDark = (data?.children as any)?.logoDark?.[0]?.ID;
  const logoLight = (data?.children as any)?.logoLight?.[0]?.ID;
  return {
    name: (data?.metadata as any)?.name,
    mode: (data?.metadata as any)?.mode,
    logoDark: logoDark ? assetsPath + logoDark : undefined,
    logoLight: logoLight ? assetsPath + logoLight : undefined
  };
};
export const useResetWatchOptions = () => {
  const { setResetWatchOptions } = useGlobalStore(
    useShallow((state) => ({
      setResetWatchOptions: state.setResetWatchOptions
    }))
  );

  useEffect(() => {
    setResetWatchOptions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
