import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useShallow } from 'zustand/react/shallow';

import { AerenaContent } from 'globals/cms-helper/types';
import { handleLogEvent } from 'globals/handleLogEvents';
import { useGlobalStore } from 'globals/store';
import { useNavigationEventStore } from 'globals/store/navigationevent.store';
import { NavigationEventParams, TNavigationEventPageName } from 'globals/types/logevent';
import { formatPageContentForLogEvent, logWatchGridEvents } from 'utils/api/cmsContents';
import { ALL_CONTENT } from 'utils/constants';
import { queryClient } from 'utils/reactQueryConfig';
import { SectionMeta } from 'globals/types/meta';

export const useNavigationLogEvent = (
  pageName: TNavigationEventPageName,
  sections: AerenaContent[],
  contents?: AerenaContent[],
  selectedOptions?: {
    mainOption: 'aerena_movie' | 'aerena_tv_show' | 'browse_all';
    secondaryOption: string;
  }
) => {
  const selectedLanguage = useGlobalStore.getState().selectedLanguage;
  const { navigationEventOriginPage, navigationEventPreviousUrl, setNavigationEventPreviousUrl } =
    useNavigationEventStore(
      useShallow((state) => ({
        navigationEventOriginPage: state.navigationEventOriginPage,
        navigationEventPreviousUrl: state.navigationEventPreviousUrl,
        setNavigationEventPreviousUrl: state.setNavigationEventPreviousUrl
      }))
    );
  const sectionID = sections?.[0]?.ID;
  useEffect(() => {
    const payload: NavigationEventParams = {
      type: 'navigation',
      eventId: uuidv4(),
      correlationId: uuidv4(),
      timestamp: new Date().toISOString(),
      originPage: {
        ...navigationEventOriginPage,
        languageSelected: selectedLanguage.code,
        type: navigationEventPreviousUrl
      },
      targetPage: {
        type: pageName,
        languageSelected: selectedLanguage.code,
        viewableElements: null
      }
    };
    const viewableElements = navigationEventContentsData(sections, pageName);
    switch (pageName) {
      case 'home_page':
      case 'watch_page':
      case 'see_all_page':
        payload.targetPage.viewableElements = viewableElements?.filter(
          (viewableElement) => viewableElement?.viewableElements?.length > 0
        );
        if (viewableElements?.length > 0) {
          handleLogEvent('NAVIGATION', payload);
          setNavigationEventPreviousUrl(pageName);
        }
        break;
      case 'watch_detail_page':
      case 'onboarding_page':
      case 'welcome_page':
        payload.targetPage.viewableElements = formatPageContentForLogEvent(sections);
        if (
          (pageName !== 'watch_detail_page' && payload?.targetPage?.viewableElements?.length > 0) ||
          (pageName === 'watch_detail_page' &&
            payload?.targetPage?.viewableElements?.length > 0 &&
            payload?.targetPage?.viewableElements?.[0]?.name ===
              (sections?.[0]?.metadata as SectionMeta)?.title)
        ) {
          handleLogEvent('NAVIGATION', payload);
          setNavigationEventPreviousUrl(pageName);
        }
        break;
      case 'watch_grid_page':
        {
          // payload for when the watch page is visited from browse all movies/TV show
          let name;
          if (selectedOptions.secondaryOption) {
            name = selectedOptions.secondaryOption;
          } else if (selectedOptions.mainOption === 'aerena_movie') {
            name = 'Movies';
          } else {
            name = 'TV Shows';
          }
          payload.targetPage.viewableElements = [
            {
              id: uuidv4(),
              name,
              rank: 1,
              type: 'TileGrid',
              viewableElements: logWatchGridEvents(contents?.filter(Boolean))
            }
          ];
          payload.targetPage.viewableElements = [
            viewableElements[0],
            ...payload.targetPage.viewableElements
          ];
          payload.targetPage.type = 'watch_page';
          handleLogEvent('NAVIGATION', payload);
          setNavigationEventPreviousUrl('watch_page');
        }
        break;
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sectionID,
    selectedLanguage.code,
    selectedOptions?.mainOption,
    selectedOptions?.secondaryOption
  ]);
};

export const navigationEventContentsData = (
  sections: AerenaContent[],
  pageName: TNavigationEventPageName
) => {
  const selectedLanguage = useGlobalStore.getState().selectedLanguage;
  const allContent: AerenaContent = queryClient.getQueryData([ALL_CONTENT, selectedLanguage.code]);

  const contents = allContent?.children?.contents;

  const data = sections?.map((section, index) => {
    const sectionMeta = section?.metadata as SectionMeta;

    const viewableElements =
      section?.children?.filter?.[0].children?.contents
        ?.map((item, index) => {
          const name =
            item?.contentTypeID === 'aerena_application'
              ? ''
              : (contents?.find((content) => content?.ID === item?.ID)?.metadata as SectionMeta)
                  ?.title;

          return {
            id: item?.ID,
            type: item?.contentTypeID as string,
            name,
            rank: index + 1
          };
        })
        ?.filter(Boolean) ?? [];

    const payload = {
      id: uuidv4(),
      rank: index + 1,
      type: sectionMeta?.type,
      name: sectionMeta?.title,
      viewableElements:
        pageName === 'see_all_page'
          ? viewableElements
          : viewableElements.slice(0, sectionMeta?.seeAllMaxLimit || 9) ?? []
    };
    return payload;
  });

  return data;
};
