import { useQuery } from '@tanstack/react-query';
import { useGlobalStore } from 'globals/store';

import { getSeatNoConfig } from 'utils/api/scsClient';
import { getEnvValue } from 'utils/useConfig';
import { useShallow } from 'zustand/react/shallow';

export const useSeatNumber = () => {
  const { aerenaStudioPreview } = useGlobalStore(
    useShallow((state) => ({
      aerenaStudioPreview: state.aerenaStudioPreview
    }))
  );

  const { previewID } = aerenaStudioPreview;

  const { data: seatNumber } = useQuery({
    queryKey: ['seatId'],
    queryFn: () => getSeatNoConfig(),
    retry: true,
    retryDelay: 5000,
    select: (data) => {
      return data?.seatId;
    },
    // If previewID OR  REACT_APP_SEAT_CONFIG_DISABLED value is not provided, it is enabled
    enabled: !(previewID || getEnvValue('REACT_APP_SEAT_CONFIG_DISABLED'))
  });
  return seatNumber;
};
