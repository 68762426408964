import { useEffect, useMemo, useState } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useGlobalStore } from 'globals/store';
import { getEnvValue } from 'utils/useConfig';
import { socketFC } from 'utils/socket';
import { setPosByElapsedTime, setRemTimeByElapsedTime } from 'utils/api/calcContentRemTime';
import { TSelectedContent } from 'globals/types/playable';

export const useFlightContextListener = (
  contentId: string,
  contentType: string,
  seatNumber: string,
  setPlayableMovie: React.Dispatch<React.SetStateAction<TSelectedContent>>
) => {
  const [socket, setSocket] = useState(null);

  const { lastWatchedEpisodeInSeries, setLastWatchedEpisodeInSeries } = useGlobalStore(
    useShallow((state) => ({
      setLastWatchedEpisodeInSeries: state.setLastWatchedEpisodeInSeries,
      lastWatchedEpisodeInSeries: state.lastWatchedEpisodeInSeries
    }))
  );

  useEffect(() => {
    if (getEnvValue('REACT_APP_FLIGHT_CONTEXT_SOCKET_URL') && seatNumber) {
      const socketFlightContext = socketFC(seatNumber);
      socketFlightContext.connect();
      setSocket(socketFlightContext);

      return () => {
        socketFlightContext.disconnect();
      };
    }
  }, [seatNumber]);

  const emit = useMemo(() => {
    if (contentType === 'aerena_movie') {
      return { message: `watchProgress/${contentId}`, id: contentId };
    } else if (contentType === 'aerena_tv_show') {
      const episodeId = lastWatchedEpisodeInSeries?.find((series) => series?.seriesId === contentId)
        ?.episode?.episodeId;
      if (episodeId) {
        return { message: `watchProgress/${episodeId}`, id: episodeId };
      }
    }
  }, [contentId, contentType, lastWatchedEpisodeInSeries]);

  useEffect(() => {
    if (socket && emit) {
      socket.emit('watch', [emit.message]);
    }
  }, [socket, emit]);

  useEffect(() => {
    const onValueChange = (data: Record<string, string>) => {
      if (data?.[emit.message] && contentType === 'aerena_movie') {
        setPlayableMovie((prev) => {
          return {
            ...prev,
            currentPosInSec: setPosByElapsedTime(prev?.runtimeInMin, Number(data[emit.message])),
            remainingTimeInMin: setRemTimeByElapsedTime(
              prev?.runtimeInMin,
              Number(data[emit.message])
            )
          };
        });
      } else if (data?.[emit.message] && contentType === 'aerena_tv_show') {
        const updatedLastWatchedEpisodeInSeries = lastWatchedEpisodeInSeries?.map((series) => {
          if (series?.seriesId === contentId && series?.episode?.episodeId === emit.id) {
            series.episode = {
              ...series.episode,
              currentPosInSec: Number(data[emit.message])
            };
          }
          return series;
        });
        setLastWatchedEpisodeInSeries(updatedLastWatchedEpisodeInSeries);
      }
    };
    if (socket) {
      socket.on('valueChanged', onValueChange);
    }

    return () => {
      if (socket) {
        socket.off('valueChanged', onValueChange);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId, contentType, socket, emit]);
};
