import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';
import 'swiper/css';

import { OptionContainer } from './watch.styled';

import { fetchContentById } from 'globals/cms-helper/layoutApi';
import { useGlobalStore } from 'globals/store';
import { getPageID } from 'globals/hooks';
import { AerenaContent } from 'globals/cms-helper/types';
import { setFullscreen } from 'utils/switchAppContext';
import { ACTIVE_LIST, ALL_CONTENT, QUERY_BY_PAGE_IDS } from 'utils/constants';
import { getCurrentLangBundle, setGenresAudioSubs } from 'globals/cms-helper/movieHelper';

import { TileCompound } from 'components/TileCompound/TileCompound';
import { WatchOption, WatchOptions } from './components/watchOptionsContainer';
import { AudioSubtitles } from 'components/AudioSubtitle';
import { FavoritesModal } from 'components/FavoritesModal';
import { WatchTileGridWrapper } from 'components/WatchTileGridWrapper';
import { WatchSwimlanesWrapper } from 'components/WatchSwimlanesWrapper';
import { SectionMeta } from 'globals/types/meta';

const WatchPage: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);

  const {
    selectedAudios,
    selectedSubtitles,
    selectedLanguage,
    watchMainOptions,
    watchSecondaryOptions,
    setWatchMainOptions,
    setWatchSecondaryOptions,
    isLanguageUpdated,
    resetWatchOptions,
    setResetWatchOptions,
    aerenaStudioPreview,
    setSelectedAudio,
    setSelectedSubtitles
  } = useGlobalStore(
    useShallow((state) => ({
      selectedAudios: state.selectedAudios,
      selectedSubtitles: state.selectedSubtitles,
      selectedLanguage: state.selectedLanguage,
      watchMainOptions: state.watchMainOptions,
      watchSecondaryOptions: state.watchSecondaryOptions,
      setWatchMainOptions: state.setWatchMainOptions,
      setWatchSecondaryOptions: state.setWatchSecondaryOptions,
      isLanguageUpdated: state.isLanguageUpdated,
      resetWatchOptions: state.resetWatchOptions,
      setResetWatchOptions: state.setResetWatchOptions,
      aerenaStudioPreview: state.aerenaStudioPreview,
      setSelectedAudio: state.setSelectedAudio,
      setSelectedSubtitles: state.setSelectedSubtitles
    }))
  );

  const watchPageID = getPageID(QUERY_BY_PAGE_IDS.WATCH);

  const { previewID, previewDate } = aerenaStudioPreview;

  // get the cached data of allContent for validation in previewID
  const { data: allContent } = useQuery({
    queryKey: [ALL_CONTENT, selectedLanguage.code],
    queryFn: () =>
      fetchContentById(ALL_CONTENT, { language: selectedLanguage, depth: 1, previewDate })
  });

  // get the cached data for watchpage
  const { data: page, isLoading } = useQuery({
    queryKey: [watchPageID, selectedLanguage.code],
    queryFn: () =>
      fetchContentById(watchPageID, {
        language: selectedLanguage,
        depth: 2,
        previewDate
      }),
    enabled: !!previewID || (!previewID && isLanguageUpdated), // paxfe should wait systemUI language update before making any query, otherwise it makes double fetch for prev and new lang,
    staleTime: 0
  });

  const pageSections: AerenaContent[] = (page?.children?.sections as any)?.[0]?.children?.contents;

  const { data: activeList } = useQuery({
    queryKey: [ACTIVE_LIST],
    queryFn: () => setGenresAudioSubs(selectedLanguage.code),
    enabled: Boolean(allContent)
  });

  useEffect(() => {
    // watchMainOptions is [] initially. Then, fill it with fetched watchOptions. set 'browse_all' true.
    // In language change watchOptions is replaced with current language. Then only replace the title but keep selected prop.
    // Loop over fetched watchOptions, update stored watchMainOptions at start-up and in each language change
    const updatedMainOptions = Object.keys(
      getCurrentLangBundle(selectedLanguage.code, 'watchMainOptions') || {}
    )?.map((o: string) => {
      const langBundle = getCurrentLangBundle(selectedLanguage.code, 'watchMainOptions');
      const option: any = watchMainOptions?.find((op: WatchOption) => o === op?.value);
      const isBrowseAll = o === 'browse_all';
      const title = langBundle?.[o];
      let selected;

      if (resetWatchOptions) {
        selected = isBrowseAll;
      } else if (option) {
        selected = option.selected;
      } else {
        selected = isBrowseAll;
      }

      return {
        title,
        value: o,
        selected
      };
    });
    const movieExist = allContent?.children?.contents?.some(
      (content) => content?.contentTypeID === 'aerena_movie'
    );
    const tvshowExist = allContent?.children?.contents?.some(
      (content) => content?.contentTypeID === 'aerena_tv_show'
    );

    if (allContent?.children?.contents) {
      // show the available content mainOptions
      setWatchMainOptions(
        updatedMainOptions?.filter(
          (option) =>
            option?.value === 'browse_all' ||
            (option?.value === 'aerena_movie' && movieExist) ||
            (option?.value === 'aerena_tv_show' && tvshowExist)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage.code, allContent?.children?.contents]);

  useEffect(() => {
    if (activeList?.activeGenres?.length > 0) {
      const filteredActiveGenres = Object.keys(
        getCurrentLangBundle(selectedLanguage.code, 'watchSecondaryOptions') || {}
      )?.filter((o: string) => activeList?.activeGenres?.some((list: string) => o === list));

      // watchSecondaryOptions is same except initially all set to false
      const updatedSecondaryOptions = filteredActiveGenres?.map((o: string) => {
        const langBundle = getCurrentLangBundle(selectedLanguage.code, 'watchSecondaryOptions');
        const option: any = watchSecondaryOptions?.find((op: WatchOption) => o === op?.value);
        const title = langBundle?.[o];
        let selected;
        if (resetWatchOptions) {
          selected = false;
        } else if (option) {
          selected = option.selected;
        } else {
          selected = false;
        }
        return {
          title,
          value: o,
          selected
        };
      });
      setWatchSecondaryOptions(updatedSecondaryOptions);
      setResetWatchOptions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage.code, activeList?.activeGenres?.length]);

  const selectOption = useCallback(
    (
      item: WatchOption,
      options: WatchOption[],
      setOptions: (options: WatchOption[]) => void,
      toggleMode: boolean
    ) => {
      const updatedOptions = options.map((option) => {
        if (option.value === item.value) {
          option.selected = toggleMode ? !option.selected : true;
        } else {
          option.selected = false;
        }
        return option;
      });
      setOptions(updatedOptions);
    },
    []
  );

  const selectMainOption = useCallback(
    (item: WatchOption) => {
      selectOption(item, watchMainOptions, setWatchMainOptions, false);
    },
    [selectOption, watchMainOptions, setWatchMainOptions]
  );

  const selectSecondaryOption = useCallback(
    (item: WatchOption) => {
      selectOption(item, watchSecondaryOptions, setWatchSecondaryOptions, true);
    },
    [selectOption, watchSecondaryOptions, setWatchSecondaryOptions]
  );

  const onAudioSubtitleClick = useCallback(() => {
    setOpenModal(true);
    setFullscreen(true);
  }, [setOpenModal]);

  const onClearSelection = () => {
    setSelectedAudio([]);
    setSelectedSubtitles([]);
    setWatchMainOptions(
      watchMainOptions.map((option) => {
        if (option.value.includes('browse_all')) option.selected = true;
        else option.selected = false;
        return option;
      })
    );
    setWatchSecondaryOptions(
      watchSecondaryOptions?.map((watchSecondaryOption: WatchOption) => ({
        ...watchSecondaryOption,
        selected: false
      }))
    );
  };

  const selectedMainOption = watchMainOptions?.find((option) => option?.selected)?.value;
  const selectedSecondaryOption = watchSecondaryOptions?.find((option) => option?.selected)?.value;

  const isAudioOrSubtitleSelected = selectedAudios?.length > 0 || selectedSubtitles?.length > 0;

  const modifiedPageSections = useMemo(() => {
    return pageSections
      ?.map((section) => {
        if ((section?.metadata as any)?.type === 'TileSwiper') {
          (section.metadata as any).slidesPerView = 1.54;
          return section;
        }
      })
      ?.filter(Boolean);
  }, [pageSections]);

  return (
    <div data-testid="watch-page-content">
      {!isLoading && pageSections?.length > 0 && isLanguageUpdated && (
        <>
          <FavoritesModal favoriteType="watch" />
          {modifiedPageSections?.[0] && (
            <TileCompound
              componentName={(modifiedPageSections?.[0]?.metadata as SectionMeta)?.type}
              section={modifiedPageSections?.[0]}
            />
          )}
          <OptionContainer>
            <WatchOptions
              mainOptions={watchMainOptions}
              secondaryOptions={watchSecondaryOptions}
              mainOptionsClickHandler={selectMainOption}
              secondaryOptionsClickHandler={selectSecondaryOption}
              audioSubtitleClickHandler={onAudioSubtitleClick}
              showAudioSubtitles={true}
              isAudioOrSubtitleSelected={isAudioOrSubtitleSelected}
            ></WatchOptions>
          </OptionContainer>
          {selectedSecondaryOption ||
          (selectedMainOption && !selectedMainOption?.includes('browse_all')) ? (
            <WatchTileGridWrapper
              selectedMainOption={selectedMainOption}
              selectedSecondaryOption={selectedSecondaryOption}
              pageSections={pageSections}
              allContent={allContent}
              onClearSelection={onClearSelection}
            />
          ) : (
            <WatchSwimlanesWrapper
              pageSections={pageSections}
              onClearSelection={onClearSelection}
            />
          )}
        </>
      )}
      {openModal && <AudioSubtitles setOpenModal={setOpenModal} />}
    </div>
  );
};

export default WatchPage;
