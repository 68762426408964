import React, { useEffect, useState } from 'react';
import {
  AboutOverview,
  AboutOurCompany,
  AboutOurResponsibility,
  AboutOurFleet,
  AboutHistory,
  AboutDestinations,
  AboutFooter,
  AboutValues
} from '@aerq/aerq-design-system';
import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import { getPageID, useScrollToTop } from 'globals/hooks';
import { QUERY_BY_PAGE_IDS } from 'utils/constants';
import { useGlobalStore } from 'globals/store';
import { fetchContentById, getAboutContent } from 'globals/cms-helper/layoutApi';
import { TAboutContent } from 'globals/types/meta';

import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';

const AboutPage: React.FC = () => {
  const [aboutData, setAboutData] = useState<TAboutContent>(null);

  const { selectedLanguage, isLanguageUpdated } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage,
      isLanguageUpdated: state.isLanguageUpdated
    }))
  );

  const aboutPageID = getPageID(QUERY_BY_PAGE_IDS.ABOUT);

  const { data } = useQuery({
    queryKey: [aboutPageID, selectedLanguage.code],
    queryFn: () => fetchContentById(aboutPageID, { language: selectedLanguage }),
    enabled: isLanguageUpdated, // paxfe should wait systemUI language update before making any query, otherwise it makes double fetch for prev and new lang
    throwOnError: true
  });

  useScrollToTop();

  useEffect(() => {
    if (data) {
      const contents = getAboutContent(data);
      setAboutData(contents);
    }
  }, [data]);

  const images = aboutData?.contents;
  const metadata = aboutData?.metadata;

  if (!aboutData) return <PlaceHolderWrapper type="AboutUs" ratio="1:1" />;

  return (
    <div data-testid="about-page">
      <AboutOverview
        title={metadata?.title_header}
        caption={metadata?.caption_header}
        backgroundImage={images?.[0]?.imageSrc}
        description={metadata?.description_header}
        buttonText={metadata?.button_header}
        buttonIcon={'ArrowDown'}
        handleClick={() =>
          document
            .getElementsByClassName('our-destinations')?.[0]
            .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }
      />
      <AboutValues
        contents={[
          {
            title: `${metadata?.title_1_values}`,
            description: `${metadata?.description_1_values}`,
            values: [
              metadata?.element_1_values_1,
              metadata?.element_1_values_2,
              metadata?.element_1_values_3
            ],
            imageSrc: `${images?.[1]?.imageSrc}`,
            imageGrid: `${images?.[13]?.imageSrc}`
          },
          {
            title: `${metadata?.title_2_values}`,
            description: `${metadata?.description_2_values}`,
            values: [
              metadata?.element_2_values_1,
              metadata?.element_2_values_2,
              metadata?.element_2_values_3
            ],
            imageSrc: `${images?.[2]?.imageSrc}`,
            imageGrid: `${images?.[13]?.imageSrc}`
          },
          {
            title: `${metadata?.title_3_values}`,
            description: `${metadata?.description_3_values}`,
            values: [
              metadata?.element_3_values_1,
              metadata?.element_3_values_2,
              metadata?.element_3_values_3
            ],
            imageSrc: `${images?.[3]?.imageSrc}`,
            imageGrid: `${images?.[14]?.imageSrc}`
          }
        ]}
      />
      <AboutOurCompany
        title={metadata?.title_about}
        caption={metadata?.caption_about}
        description={metadata?.description_about}
        imageSrc={images?.[4]?.imageSrc}
      />
      <AboutOurResponsibility
        contents={[
          {
            caption: `${metadata?.caption_responsibilites}`,
            title: `${metadata?.title_1_responsibilities}`,
            description: `${metadata?.description_1_responsibilities}`
          },
          {
            caption: `${metadata?.caption_responsibilites}`,
            title: `${metadata?.title_2_responsibilities}`,
            description: `${metadata?.description_2_responsibilities}`
          }
        ]}
      />
      <AboutOurFleet
        title={metadata?.title_fleet}
        description={metadata?.description_fleet}
        contents={[
          {
            imageSrc: `${images?.[5]?.imageSrc}`,
            title: `${metadata?.aircraft_1_fleet}`
          },
          {
            imageSrc: `${images?.[6]?.imageSrc}`,
            title: `${metadata?.aircraft_2_fleet}`
          },
          {
            imageSrc: `${images?.[7]?.imageSrc}`,
            title: `${metadata?.aircraft_3_fleet}`
          }
        ]}
      />
      <AboutHistory
        title={metadata?.title_history}
        mainSectionText={''}
        milestones={[
          {
            date: `${metadata?.section_1_year}`,
            description: `${metadata?.section_1_history}`
          },
          {
            date: `${metadata?.section_2_year}`,
            description: `${metadata?.section_2_history}`
          },
          {
            date: `${metadata?.section_3_year}`,
            description: `${metadata?.section_3_history}`
          },
          {
            date: `${metadata?.section_4_year}`,
            description: `${metadata?.section_4_history}`
          }
        ]}
      />
      <AboutDestinations
        title={metadata?.title_destinations}
        mainSectionText={metadata?.description_destinations}
        mapSrc={`${images?.[8]?.imageSrc}`}
        destinations={[
          {
            imageSrc: `${images?.[9]?.imageSrc}`,
            width: '37.6rem',
            description: `${metadata?.caption_1_gallery}`
          },
          {
            imageSrc: `${images?.[10]?.imageSrc}`,
            width: '41.4rem',
            description: `${metadata?.caption_2_gallery}`
          },
          {
            imageSrc: `${images?.[11]?.imageSrc}`,
            width: '41.4rem',
            description: `${metadata?.caption_3_gallery}`
          }
        ]}
        className="our-destinations"
      />
      <AboutFooter
        logo={`${images?.[12]?.imageSrc}`}
        link={metadata?.link}
        info={[
          [metadata?.text_1_footer_de, metadata?.text_2_footer_de, metadata?.text_3_footer_de],
          [metadata?.text_1_footer_en, metadata?.text_2_footer_en, metadata?.text_3_footer_en]
        ]}
      />
    </div>
  );
};

export default AboutPage;
