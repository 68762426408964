import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tile } from '@aerq/aerq-design-system';
import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';
import { useTheme } from 'styled-components';

import {
  WatchDetailsHeaderContent,
  WatchDetailsHeaderInfo,
  WatchDetailsHeader,
  WatchDetailsHeaderTitle,
  WatchDetailsHeaderType,
  PlayAction,
  WatchDetailsProgressBar,
  WatchDetailImageContainer,
  RemainingTime
} from './heroHeader.styled';

import { hexToRgb, isTextDark } from 'utils/textColor';
import { assetsPath, sdkInstance } from 'globals/api';
import {
  getContentMetaData,
  getCurrentLangBundle,
  setGenresAudioSubs
} from 'globals/cms-helper/movieHelper';
import { getImageWithFallback } from 'globals/cms-helper/layoutApi';
import { useGlobalStore } from 'globals/store';
import { AerenaContent, AerenaContentType } from 'globals/cms-helper/types';
import { useFavorites } from 'globals/hooks';
import { useSeatNumber } from 'globals/hooks/useSeatNumber';
import { formatMinuteRuntimeToInfo } from 'utils/runtimeFormat';
import { ACTIVE_LIST } from 'utils/constants';
import { getEnvValue } from 'utils/useConfig';
import {
  calcWatchedTimeInPercent,
  updateSelectedEpisodeInfoInStore,
  getContentRuntime,
  getContentRemainingTime,
  getContentElapsedTime,
  updatePlayableMovie,
  updatePlayableTVShow
} from 'utils/api/calcContentRemTime';
import { useFlightContextListener } from 'globals/hooks/useFlightContextListener';
import { pauseEpisode } from 'utils/api/audioPlayer';
import {
  TCurrentSeason,
  TLastWatchedEpisodeInSeries,
  TPlayableVideo,
  TSelectedContent
} from 'globals/types/playable';
import { TEpisodeTileItem } from 'globals/types/tile';

import ProgressBar from './progressBar';
import ExpandableText from 'components/ExpandableText/ExpandableText';

interface HeroHeaderProps {
  content?: AerenaContent;
  info?: { key: string; value: any[] };
  season?: TCurrentSeason;
  episodes?: AerenaContent[];
  episodeTileItems: TEpisodeTileItem[];
  setEpisodeTileItems: React.Dispatch<React.SetStateAction<TEpisodeTileItem[]>>;
  setPreRollVideo: React.Dispatch<React.SetStateAction<boolean>>;
  setPreRollVideoURL: React.Dispatch<React.SetStateAction<string>>;
  setPlayableVideo: React.Dispatch<React.SetStateAction<TPlayableVideo>>;
}

const HeroHeader: React.FC<HeroHeaderProps> = ({
  content,
  info,
  season,
  episodes,
  episodeTileItems,
  setEpisodeTileItems,
  setPreRollVideo,
  setPreRollVideoURL,
  setPlayableVideo
}) => {
  const [playableEpisode, setPlayableEpisode] = useState<TSelectedContent>(null);
  const [playableMovie, setPlayableMovie] = useState<TSelectedContent>(null);

  const {
    selectedLanguage,
    isLanguageUpdated,
    aerenaStudioPreview,
    lastWatchedEpisodeInSeries,
    setLastWatchedEpisodeInSeries
  } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage,
      isLanguageUpdated: state.isLanguageUpdated,
      aerenaStudioPreview: state.aerenaStudioPreview,
      lastWatchedEpisodeInSeries: state.lastWatchedEpisodeInSeries,
      setLastWatchedEpisodeInSeries: state.setLastWatchedEpisodeInSeries
    }))
  );

  const theme = useTheme();
  const seatNumber = useSeatNumber();
  const { t } = useTranslation(['favorites', 'watch']);
  const { toggleFavorite, isFavorite } = useFavorites('watch');

  const contentType = content?.contentTypeID;
  const defaultDominantColor = hexToRgb(theme?.colors?.gray20);
  const informationLabel = t('watch:details.information.title');
  const isContentFavorite = content ? isFavorite(content.ID) : false;
  const favoriteLabel = isContentFavorite
    ? t('favorites:removeFavorites')
    : t('favorites:addFavorites');
  const { previewID } = aerenaStudioPreview;

  useFlightContextListener(content?.ID, contentType, seatNumber, setPlayableMovie);

  const { data: genres } = useQuery({
    queryKey: [ACTIVE_LIST],
    queryFn: () => setGenresAudioSubs(selectedLanguage.code),
    select: (data) => {
      return data?.activeGenres
        ?.filter((genre: string) => {
          return (content?.metadata as any)?.genres?.includes(genre);
        })
        ?.map(
          (g: string) => getCurrentLangBundle(selectedLanguage.code, 'watchSecondaryOptions')?.[g]
        )
        ?.filter(Boolean);
    },
    enabled: content && isLanguageUpdated // paxfe should wait systemUI language update before making any query, otherwise it makes double fetch for prev and new lang
  });

  const contentRuntime = useMemo(
    () => getContentRuntime(contentType, playableMovie, playableEpisode),
    [contentType, playableMovie, playableEpisode]
  );
  const contentRemTime = useMemo(
    () => getContentRemainingTime(contentType, playableMovie, playableEpisode),
    [contentType, playableMovie, playableEpisode]
  );
  const contentElapsedTime = useMemo(
    () => getContentElapsedTime(contentType, playableMovie, playableEpisode),
    [contentType, playableMovie, playableEpisode]
  );

  useEffect(() => {
    updatePlayableMovie(contentType, content, seatNumber, setPlayableMovie);
  }, [content, contentType, seatNumber]);

  useEffect(() => {
    updatePlayableTVShow(
      contentType,
      content,
      season,
      episodes,
      lastWatchedEpisodeInSeries,
      episodeTileItems,
      setEpisodeTileItems,
      setPlayableEpisode,
      setLastWatchedEpisodeInSeries
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.ID, contentType, episodes, season, lastWatchedEpisodeInSeries]);

  const { dominantColor, headerImage, meta, infos, isDarkText } = useMemo(() => {
    let headerImage: string;
    let dominantColor;
    let meta;
    let infos: string;
    let isDarkText;

    if (content) {
      const { imageSrc, dominantColor: dominantCol } = getImageWithFallback(
        content,
        '16:9',
        'still',
        '88rem'
      );
      dominantColor = dominantCol;

      if (imageSrc) {
        headerImage = assetsPath + imageSrc;
      }

      meta = getContentMetaData(content);
      infos = [
        genres?.slice(0, 3)?.join(', '),
        meta?.ratings?.slice(0, 3)?.join(', '),
        meta?.releaseDate || meta?.releaseYear,
        contentType === 'aerena_tv_show'
          ? meta?.numberOfSeasons
          : formatMinuteRuntimeToInfo(meta?.runtime || 0)
      ]
        .filter(Boolean)
        .join(' • ');
      isDarkText = isTextDark(dominantCol ?? defaultDominantColor);
    }

    return { headerImage, dominantColor, meta, infos, isDarkText };
  }, [content, genres, contentType, defaultDominantColor]);

  const getButtonLabel = (
    contentType: AerenaContentType,
    contentElapsedTime: number,
    t: any,
    playableEpisode: TSelectedContent
  ) => {
    const resumeText = t('watch:resumeButton');
    const playText = t('watch:playButton');
    const hasWatchedContent = contentElapsedTime >= 5;

    if (contentType === 'aerena_movie') {
      return hasWatchedContent ? resumeText : playText;
    } else {
      const seasonText = playableEpisode?.seasonNum ? `S${playableEpisode.seasonNum} ` : '';
      const episodeText = playableEpisode?.episodeNum ? `E${playableEpisode.episodeNum}` : '';
      return hasWatchedContent
        ? `${resumeText} ${seasonText}${episodeText}`
        : `${playText} ${seasonText}${episodeText}`;
    }
  };

  const buttonLabel = getButtonLabel(contentType, contentElapsedTime, t, playableEpisode);

  const setPlayableVideoContent = (
    contentType: AerenaContentType,
    content: AerenaContent,
    playableMovie: TSelectedContent,
    playableEpisode: TSelectedContent,
    setPlayableVideo: React.Dispatch<React.SetStateAction<TPlayableVideo>>,
    lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries,
    setLastWatchedEpisodeInSeries: (lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries) => void
  ) => {
    if (contentType === 'aerena_movie') {
      setPlayableVideo({
        ID: playableMovie?.ID,
        type: content?.contentTypeID as AerenaContentType,
        title: playableMovie?.title,
        path: playableMovie?.url,
        currentPos: playableMovie?.currentPosInSec
      });
    }
    if (contentType === 'aerena_tv_show') {
      setPlayableVideo({
        ID: content?.ID,
        episodeId: playableEpisode?.ID,
        type: content?.contentTypeID as AerenaContentType,
        title: playableEpisode?.title,
        path: playableEpisode?.url,
        currentPos: playableEpisode?.currentPosInSec
      });
      updateSelectedEpisodeInfoInStore(
        lastWatchedEpisodeInSeries,
        setLastWatchedEpisodeInSeries,
        content,
        playableEpisode,
        playableEpisode?.currentPosInSec
      );
    }
  };

  const playPreRollVideo = useCallback(() => {
    sdkInstance?.setContext('onboarding');
    // pause the podcast in any case when video is triggered
    pauseEpisode();

    setPreRollVideo(true);
    setPreRollVideoURL(
      `${getEnvValue('REACT_APP_PRE_ROLL_AD_URL')}?section=${
        contentType === 'aerena_movie' ? 'MOVIE' : 'TVSHOW'
      }&isdId=${seatNumber}&platform=IFE&orientation=LANDSCAPE&limit=1`
    );

    setPlayableVideoContent(
      contentType,
      content,
      playableMovie,
      playableEpisode,
      setPlayableVideo,
      lastWatchedEpisodeInSeries,
      setLastWatchedEpisodeInSeries
    );
  }, [
    setPreRollVideo,
    setPreRollVideoURL,
    contentType,
    seatNumber,
    content,
    playableMovie,
    playableEpisode,
    setPlayableVideo,
    lastWatchedEpisodeInSeries,
    setLastWatchedEpisodeInSeries
  ]);

  return (
    <WatchDetailsHeader data-testid="hero-header-watch">
      <WatchDetailsHeaderContent
        dominantCol={dominantColor}
        defaultDominantCol={defaultDominantColor}
      >
        <WatchDetailsHeaderInfo>
          <WatchDetailsHeaderType isTextDark={isDarkText}>{infos}</WatchDetailsHeaderType>
          <WatchDetailsHeaderTitle isTextDark={isDarkText}>{meta?.title}</WatchDetailsHeaderTitle>
          <ExpandableText
            text={meta?.synopsis}
            isTextDark={isDarkText}
            seeMoreLabel={t('watch:details.see_more')}
            seeLessLabel={t('watch:details.see_less')}
          />
          <div style={{ marginTop: 'auto', width: 'fit-content' }}>
            <RemainingTime>
              {contentRemTime > 0 && (
                <WatchDetailsProgressBar isTextDark={isDarkText}>
                  <p>
                    {/* check if remaining time is less than a minute or not */}
                    {contentRuntime * 60 - contentElapsedTime < 60 && '<'} {contentRemTime}
                    {t('watch:remainingTime')}
                  </p>
                  <ProgressBar
                    width={calcWatchedTimeInPercent(contentRuntime, contentElapsedTime / 60)}
                    isTextDark={isDarkText}
                  />
                </WatchDetailsProgressBar>
              )}
            </RemainingTime>
            <PlayAction>
              <Button
                buttonMode={isDarkText ? 'dark' : 'light'}
                icon="Play"
                buttonSize="m"
                handleClick={playPreRollVideo}
                disabled={!!previewID}
              >
                {buttonLabel}
              </Button>
              {info?.value?.some((it: any) => it?.value) && (
                <Button
                  icon={'Information'}
                  buttonMode={isDarkText ? 'dark' : 'light'}
                  buttonType="transparent"
                  handleClick={() =>
                    document
                      .getElementById('information')
                      ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                  }
                >
                  {informationLabel}
                </Button>
              )}
              <Button
                buttonType="transparent"
                icon={isFavorite(content?.ID) ? 'Close' : 'Star'}
                buttonMode={isDarkText ? 'dark' : 'light'}
                handleClick={() => toggleFavorite(content)}
              >
                {favoriteLabel}
              </Button>
            </PlayAction>
          </div>
        </WatchDetailsHeaderInfo>

        <WatchDetailImageContainer>
          <Tile
            theme={theme}
            imageSrc={headerImage}
            width="100%"
            aspectRatio="16:9"
            textPosition="none"
            className="watch-header_image"
            noImageText={t('common:error_page.no_image.text')}
          />
        </WatchDetailImageContainer>
      </WatchDetailsHeaderContent>
    </WatchDetailsHeader>
  );
};

export default HeroHeader;
