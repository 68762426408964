import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';
import { Button, Icon, RadioButton, TIconName } from '@aerq/aerq-design-system';
import { useTheme } from 'styled-components';

import { fetchContentById, getLanguages, getWelcomeContent } from 'globals/cms-helper/layoutApi';
import { useGlobalStore } from 'globals/store';
import { getPageID } from 'globals/hooks';
import { useFlightDataListener } from 'globals/hooks/useFlightDataListener';
import { useNavigationEventStore } from 'globals/store/navigationevent.store';
import { useNavigationLogEvent } from 'globals/hooks/useNavigationLogEvent';
import { QUERY_BY_CONTENT_IDS } from 'utils/constants';
import { setLocale } from 'utils/switchAppContext';
import { useGetTheme } from 'globals/styles';
import { TWelcomeMetadata } from 'globals/types/meta';

import {
  ActionContainer,
  Container,
  ContentLeft,
  LanguageContainer,
  LogoContainer,
  Overlay,
  WelcomeContent
} from './welcome.styled';

import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';
import { FlightInformation } from 'components/FlightInformation';

const WelcomePage: React.FC = () => {
  const [welcomeData, setWelcomeData] = useState<TWelcomeMetadata>();
  const { logo } = useGetTheme();

  const theme = useTheme();

  const navigate = useNavigate();

  const { selectedLanguage, setSelectedLanguage, setIsStartup, isLanguageUpdated } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage,
      setSelectedLanguage: state.setSelectedLanguage,
      setIsStartup: state.setIsStartup,

      isLanguageUpdated: state.isLanguageUpdated
    }))
  );

  const { setNavigationEventOriginPage, setNavigationEventPreviousUrl } = useNavigationEventStore(
    useShallow((state) => ({
      setNavigationEventOriginPage: state.setNavigationEventOriginPage,
      setNavigationEventPreviousUrl: state.setNavigationEventPreviousUrl
    }))
  );

  useFlightDataListener();

  // get the cached data or fetch from CMS
  const { data: languages } = useQuery({
    queryKey: [QUERY_BY_CONTENT_IDS.ACTIVE_LANGS],
    queryFn: () => getLanguages(QUERY_BY_CONTENT_IDS.ACTIVE_LANGS),
    throwOnError: true
  });

  const welcomePageID = getPageID(QUERY_BY_CONTENT_IDS.WELCOME);

  const { data } = useQuery({
    queryKey: [welcomePageID, selectedLanguage.code],
    queryFn: () => fetchContentById(welcomePageID, { language: selectedLanguage }),
    enabled: isLanguageUpdated, // paxfe should wait systemUI language update before making any query, otherwise it makes double fetch for prev and new lang
    throwOnError: true
  });

  const fetchImages = useCallback(async (data: TWelcomeMetadata, logo: string) => {
    await Promise.all([axios.get(data?.backgroundImage), axios.get(logo)]);
    return true;
  }, []);

  useQuery({
    queryKey: ['welcome_image'],
    queryFn: () => fetchImages(welcomeData, logo),
    staleTime: 0,
    throwOnError: true,
    enabled: !!welcomeData && !!logo
  });

  useNavigationLogEvent('welcome_page', [data]);

  useEffect(() => {
    if (data) {
      const contents = getWelcomeContent(data);
      setWelcomeData(contents);
    }
  }, [data]);

  const { t, i18n } = useTranslation(['errors, welcome']);
  useEffect(() => {
    setNavigationEventOriginPage({
      type: 'welcomePage',
      languageSelected: selectedLanguage.code,
      clickedElement: {
        id: 'getStartedButton',
        name: 'Get Started Button',
        type: 'button',
        rank: 0
      }
    });
    setNavigationEventPreviousUrl('welcome_page');
  }, [setNavigationEventOriginPage, setNavigationEventPreviousUrl, selectedLanguage.code]);

  const selectLanguageHandler = useCallback(
    (langCode: string) => {
      const language = languages?.find((lang) => lang?.code === langCode);
      if (!language) return;
      setSelectedLanguage(language);
      i18n.changeLanguage(language.code);
    },
    [languages, setSelectedLanguage, i18n]
  );

  const getStartedHandler = useCallback(() => {
    setIsStartup(false);
    setLocale(selectedLanguage);
    setNavigationEventOriginPage({
      type: 'welcomePage',
      languageSelected: selectedLanguage.code,
      clickedElement: {
        id: 'getStartedButton',
        name: 'Get Started Button',
        type: 'button',
        rank: 0
      }
    });
    navigate('/onboarding', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsStartup, selectedLanguage.code, setNavigationEventOriginPage]);

  const isSelectedLangauge = useCallback(
    (code: string) => {
      return selectedLanguage.code === code;
    },
    [selectedLanguage.code]
  );

  return !welcomeData ? (
    <PlaceHolderWrapper type="Welcome" ratio="3:4" showPlaceholderTitle={false} />
  ) : (
    <Container bgImage={welcomeData?.backgroundImage}>
      <Overlay></Overlay>
      <LogoContainer>
        {logo ? (
          <img alt="logo" src={logo} />
        ) : (
          <Icon name={theme?.logoName as TIconName} iconStyle={'none'}></Icon>
        )}
      </LogoContainer>

      <WelcomeContent>
        <ContentLeft>
          <div className="display2 primaryBlack">{welcomeData?.title}</div>
          <div className="bodyL body">{welcomeData?.description}</div>
          <div className="bodyXL body">{t('welcome:languageHeader')}</div>
          <LanguageContainer>
            {(languages || [selectedLanguage]).map((language) => (
              <RadioButton
                width="100%"
                icon={language.icon}
                iconUseRadioStyle={false}
                key={language.code}
                radioType={isSelectedLangauge(language.code) ? 'filled' : 'transparent'}
                radioStyle="dark"
                checked={isSelectedLangauge(language.code)}
                onClick={() => selectLanguageHandler(language.code)}
                data-testid={`language-button-${language.code}`}
              >
                {language.name}
              </RadioButton>
            ))}
          </LanguageContainer>
        </ContentLeft>
        <FlightInformation />
      </WelcomeContent>
      <ActionContainer>
        <div className="welcome-btn">
          <Button buttonMode="dark" width="27.2rem" handleClick={getStartedHandler}>
            {t('welcome:startButtonText')}
          </Button>
        </div>
      </ActionContainer>
    </Container>
  );
};

export default WelcomePage;
