import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { TLanguage } from 'globals/types/language';
import { WatchOption } from 'views/watch/components/watchOptionsContainer';
import { TAerenaStudioPreview } from 'globals/types';
import { getPreviewData } from 'utils/previewConfig';
import { TLastWatchedEpisodeInSeries } from 'globals/types/playable';

interface State {
  isStartup: boolean;
  allowedRatings: string[];
  selectedLanguage: TLanguage;
  selectedAudios: string[];
  selectedSubtitles: string[];
  aerenaStudioPreview: TAerenaStudioPreview;
  watchMainOptions: WatchOption[];
  watchSecondaryOptions: WatchOption[];
  currentPodcastPlayed: string;
  isLanguageUpdated: boolean;
  resetWatchOptions: boolean;
  lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries;
  watchOptionsSkipMap: Map<string, Map<string, number>>;
}

export interface IGlobalStore extends State {
  setIsStartup: (isStartup: boolean) => void;
  setAerenaStudioPreview: (aerenaStudioPreview: TAerenaStudioPreview) => void;
  resetGlobalState?: () => void;
  setSelectedLanguage: (selectedLanguage: TLanguage) => void;
  setSelectedAudio: (selectedAudio: string[]) => void;
  setSelectedSubtitles: (selectedSubtitles: string[]) => void;
  setWatchMainOptions: (watchMainOptions: WatchOption[]) => void;
  setWatchSecondaryOptions: (watchSecondaryOptions: WatchOption[]) => void;
  setCurrentPodcastPlayed: (currentPodcastPlayed: string) => void;
  setMediaRestriction: (allowedRatings: string[]) => void;
  setIsLanguageUpdated: (isLanguageUpdated: boolean) => void;
  setResetWatchOptions: (resetWatchOptions: boolean) => void;
  setLastWatchedEpisodeInSeries: (lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries) => void;
  setWatchOptionsSkipMap: (watchOptionsSkipMap: Map<string, Map<string, number>>) => void;
}

export const defaultLanguage: TLanguage = {
  code: 'de',
  name: 'Deutsch',
  icon: 'GermanyFlag',
  locale: 'de_DE'
};

const { previewID } = getPreviewData();

const initialState: State = {
  isStartup: true,
  allowedRatings: [],
  selectedLanguage: {
    ...defaultLanguage
  },
  selectedAudios: [],
  selectedSubtitles: [],
  aerenaStudioPreview: {
    previewDate: '',
    previewID: ''
  },
  currentPodcastPlayed: '',
  watchMainOptions: null,
  watchSecondaryOptions: null,
  isLanguageUpdated: false,
  resetWatchOptions: false,
  lastWatchedEpisodeInSeries: [],
  watchOptionsSkipMap: null
};

export const useGlobalStore = create<IGlobalStore>()(
  persist(
    (set) => ({
      ...initialState,
      setIsStartup: (isStartup: boolean) => {
        set({ isStartup });
      },
      resetGlobalState: () => {
        set(() => ({ ...initialState }));
      },
      setMediaRestriction: (allowedRatings: string[]) => {
        allowedRatings && set({ allowedRatings });
      },
      setIsLanguageUpdated: (isLanguageUpdated: boolean) => {
        set({ isLanguageUpdated });
      },
      setSelectedLanguage: (selectedLanguage: TLanguage) => {
        set({ selectedLanguage });
      },
      setResetWatchOptions: (resetWatchOptions: boolean) => {
        set({ resetWatchOptions });
      },
      setAerenaStudioPreview: (aerenaStudioPreview: TAerenaStudioPreview) => {
        set({ aerenaStudioPreview });
      },
      setCurrentPodcastPlayed: (currentPodcastPlayed: string) => {
        set({ currentPodcastPlayed });
      },
      setSelectedAudio: (selectedAudios: string[]) => {
        selectedAudios && set({ selectedAudios });
      },
      setSelectedSubtitles: (selectedSubtitles: string[]) => {
        selectedSubtitles && set({ selectedSubtitles });
      },
      setWatchMainOptions: (watchMainOptions: WatchOption[]) => {
        set({ watchMainOptions });
      },
      setWatchSecondaryOptions: (watchSecondaryOptions: WatchOption[]) => {
        set({ watchSecondaryOptions });
      },
      setLastWatchedEpisodeInSeries: (lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries) => {
        set({ lastWatchedEpisodeInSeries });
      },
      setWatchOptionsSkipMap: (watchOptionsSkipMap: Map<string, Map<string, number>>) => {
        set({ watchOptionsSkipMap });
      }
    }),
    {
      name: 'global',
      storage: createJSONStorage(() => {
        return previewID ? sessionStorage : localStorage;
      }),
      partialize: (state) => ({
        allowedRatings: state.allowedRatings,
        isStartup: state.isStartup,
        selectedLanguage: state.selectedLanguage,
        currentPodcastPlayed: state.currentPodcastPlayed,
        selectedAudios: state.selectedAudios,
        selectedSubtitles: state.selectedSubtitles,
        watchMainOptions: state.watchMainOptions,
        watchSecondaryOptions: state.watchSecondaryOptions,
        resetWatchOptions: state.resetWatchOptions,
        lastWatchedEpisodeInSeries: state.lastWatchedEpisodeInSeries
      })
    }
  )
);
