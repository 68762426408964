import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useShallow } from 'zustand/react/shallow';

import {
  HomePage,
  WatchPage,
  AppsPage,
  WatchDetailsPage,
  SeeAllPage,
  WelcomePage,
  ListenPage,
  ListenDetailsPage,
  Onboarding,
  AboutPage,
  EJournalPage,
  ErrorPage
} from 'views';
import { paths } from 'globals/paths';

import {
  useHandlePathContext,
  useSystemSelectedLanguage,
  useMediaRestrictionListener,
  usePrefetchContents
} from 'globals/hooks';
import { useGlobalStore } from 'globals/store/global.store';
import { ThemeProvider } from 'styled-components';
import { useGetTheme } from 'globals/styles';
import { useSeatNumber } from 'globals/hooks/useSeatNumber';

const App: React.FC = () => {
  const { isStartup, aerenaStudioPreview } = useGlobalStore(
    useShallow((state) => ({
      isStartup: state.isStartup,
      aerenaStudioPreview: state.aerenaStudioPreview
    }))
  );

  const theme = useGetTheme();

  const { previewID, previewDate } = aerenaStudioPreview;

  useSystemSelectedLanguage();
  useHandlePathContext();
  useMediaRestrictionListener();
  useSeatNumber();
  usePrefetchContents(isStartup, previewID, previewDate);

  return (
    <Routes>
      <Route
        path={paths.home.value}
        element={isStartup ? <Navigate to="/welcome" replace /> : <HomePage />}
      />
      <Route
        path={`${paths.home.value}?preview-id=:previewId`}
        element={isStartup ? <Navigate to="/welcome" replace /> : <HomePage />}
      />
      <Route
        path={paths.onboarding.value}
        element={
          <ThemeProvider theme={theme?.secondaryTheme}>
            <Onboarding />
          </ThemeProvider>
        }
      />
      <Route path={paths.watch.value} element={<WatchPage />} />
      <Route path={`${paths.watch.value}?preview-id=:previewId`} element={<WatchPage />} />
      <Route path={paths.apps.value} element={<AppsPage />} />
      <Route
        path={paths.welcome.value}
        element={
          <ThemeProvider theme={theme?.secondaryTheme}>
            <WelcomePage />
          </ThemeProvider>
        }
      />
      <Route path={`${paths.watch.value}/:id`} element={<WatchDetailsPage />} />
      <Route
        path={`${paths.watch.value}/:id?preview-id=:previewId`}
        element={<WatchDetailsPage />}
      />
      <Route path={`${paths.seeAll.value}/:id`} element={<SeeAllPage />} />
      <Route path={paths.listen.value} element={<ListenPage />} />
      <Route path={`${paths.listen.value}/:id`} element={<ListenDetailsPage />} />
      <Route
        path={`${paths.listen.value}/:id?preview-id=:previewId`}
        element={<ListenDetailsPage />}
      />
      <Route path={paths.about.value} element={<AboutPage />} />
      <Route path={paths.ejournal.value} element={<EJournalPage />} />
      <Route
        path="*"
        element={
          <ErrorPage
            error={new Error()}
            resetErrorBoundary={() => {
              ('');
            }}
          />
        }
      />
    </Routes>
  );
};

export default App;
