import React from 'react';
import { useTranslation } from 'react-i18next';

import { TileGrid } from '@aerq/aerq-design-system';
import { useTheme } from 'styled-components';

import { pageXMargin } from 'globals/styles';
import { useGetSwimlaneContents, useTileClickHandler } from 'globals/hooks';
import { SectionMeta } from 'globals/types/meta';
import { AerenaContent } from 'globals/cms-helper/types';

import { GridContainer } from './TileGridWrapper.styled';

import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';

export const TileGridWrapper: React.FC<{ section: AerenaContent; sectionLoading?: boolean }> = ({
  section,
  sectionLoading
}) => {
  // sectionMeta contains Tile container properties
  const sectionMeta = section?.metadata as SectionMeta;
  const theme = useTheme();
  const { t } = useTranslation();

  const { tileItems } = useGetSwimlaneContents(section, 'Grid');

  const contentLen = section?.children?.filter?.[0]?.children?.contents?.length;
  const tileClickHandler = useTileClickHandler(section);

  return (
    <>
      {!tileItems || sectionLoading ? (
        <PlaceHolderWrapper
          type="TileContainerAndGrid"
          ratio={sectionMeta?.tileRatio}
          showPlaceholderTitle={true}
          noOfTile={contentLen}
          columns={4}
        />
      ) : (
        tileItems?.length > 0 && (
          <GridContainer xMargin={pageXMargin}>
            <TileGrid
              theme={theme}
              title={sectionMeta?.title}
              tagTextMode="light"
              textPosition={sectionMeta?.textPosition}
              textAlignment={sectionMeta?.textAlignment}
              showTitle={sectionMeta?.showTitle}
              showCaption={sectionMeta?.showCaption}
              showDescription={sectionMeta?.showDescription}
              showSub={sectionMeta?.showSub}
              showTag={sectionMeta?.showTag}
              ratio={sectionMeta?.tileRatio}
              textPadding={'0'}
              width={'100%'}
              items={tileItems}
              columns={sectionMeta?.columns}
              onTileClick={tileClickHandler}
              noImageText={t('common:error_page.no_image.text')}
            ></TileGrid>
          </GridContainer>
        )
      )}
    </>
  );
};
