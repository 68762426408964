import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FlightBarWrapper } from './FlightBar.styled';

import logo from '../../assets/flightStatus/discover_airlines_rgb_sky-blue.svg';
import flightStatus from '../../assets/flightStatus/discover_flight_status.svg';
import basket from '../../assets/flightStatus/circle.svg';
import { Spacer } from 'globals/styles';

const FlightBar: React.FC = () => {
  const navigate = useNavigate();
  const isStartPage = ['/welcome', '/onboarding'].includes(location.pathname);

  if (isStartPage) {
    return null;
  }
  return process.env.NODE_ENV === 'development' ? (
    <FlightBarWrapper>
      <img src={logo} alt="logo" onClick={() => navigate('/')} />
      <img src={flightStatus} alt="Flight status" />
      <img src={basket} alt="Basket" />
    </FlightBarWrapper>
  ) : (
    <Spacer />
  );
};

export default FlightBar;
