import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';
import { Button, CircleButton, Tile } from '@aerq/aerq-design-system';
import { useTheme } from 'styled-components';

import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';

import {
  Container,
  OnboardingOverlay,
  OnboardingContent,
  OnboardingRight,
  OnboardingLeft,
  SliderContainer,
  SliderContent,
  ActionBottomContainer,
  ActionTopContainer
} from './onboarding.styled';

import { useGlobalStore } from 'globals/store';
import { fetchContentById, getOnboardingContents } from 'globals/cms-helper/layoutApi';
import { getPageID } from 'globals/hooks';
import { useNavigationEventStore } from 'globals/store/navigationevent.store';
import { useNavigationLogEvent } from 'globals/hooks/useNavigationLogEvent';
import { SurveyType, getSurveyById } from 'utils/api/survey';
import { QUERY_BY_CONTENT_IDS } from 'utils/constants';
import { getEnvValue } from 'utils/useConfig';
import { TOnboardingContent } from 'globals/types/meta';

import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';

const Onboarding = () => {
  const theme = useTheme();
  const { selectedLanguage } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage
    }))
  );

  const setNavigationEventOriginPage = useNavigationEventStore(
    (state) => state.setNavigationEventOriginPage
  );

  const navigate = useNavigate();

  const [onboardingContents, setOnboardingContents] = useState<TOnboardingContent>();

  const swiperRef = useRef();

  const sliderItems = onboardingContents?.contents;
  const metadata = onboardingContents?.metadata;
  const backgroundImage = metadata?.backgroundImage;

  // get the cached data or fetch from CMS
  const onboardingPageID = getPageID(QUERY_BY_CONTENT_IDS.ONBOARDING);

  const { data } = useQuery({
    queryKey: [onboardingPageID, selectedLanguage.code],
    queryFn: () => fetchContentById(onboardingPageID, { language: selectedLanguage }),
    throwOnError: true
  });

  useEffect(() => {
    if (data) {
      const contents = getOnboardingContents(data);
      setOnboardingContents(contents);
    }
  }, [data]);

  useNavigationLogEvent('onboarding_page', [data]);

  const onContinue = useCallback(() => {
    setNavigationEventOriginPage({
      type: 'onBoardingPage',
      languageSelected: selectedLanguage.code,
      clickedElement: {
        id: 'onboardingSkipBtn',
        rank: 0,
        name: 'Onboarding Skip Button',
        type: 'button'
      }
    });
    navigate(metadata?.url, {
      replace: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavigationEventOriginPage, selectedLanguage.code, metadata?.url]);

  const onContinueToOnboardingSurvey = useCallback(async () => {
    try {
      const REACT_APP_SURVEY_URL = getEnvValue('REACT_APP_SURVEY_URL');
      const { ID: surveyId } = await getSurveyById(SurveyType.onboarding);
      const redirectUrl = `${REACT_APP_SURVEY_URL}/${surveyId}`;
      setNavigationEventOriginPage({
        type: 'onBoardingPage',
        languageSelected: selectedLanguage.code,
        clickedElement: {
          id: 'onboardingContinueBtn',
          rank: 0,
          name: 'Onboarding Continue Button',
          type: 'button'
        }
      });
      window.location.replace(redirectUrl);
    } catch (error) {
      navigate(metadata?.url, {
        replace: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage.code, metadata?.url]);

  const goBack = () => navigate('/welcome', { replace: true });

  const { t } = useTranslation();

  return !onboardingContents ? (
    <PlaceHolderWrapper
      type="Onboarding"
      ratio="3:4"
      noOfTile={3}
      showPlaceholderTitle={false}
      goBack={goBack}
    />
  ) : (
    <Container backgroundImage={backgroundImage}>
      <OnboardingOverlay>
        <ActionTopContainer>
          <CircleButton
            buttonMode="dark"
            buttonType="transparent"
            size="m"
            icon="ArrowBack"
            handleClick={goBack}
          ></CircleButton>
        </ActionTopContainer>
        <OnboardingContent>
          <OnboardingLeft>
            <h1>{metadata?.title}</h1>
            <p>{metadata?.description}</p>
          </OnboardingLeft>
          <OnboardingRight>
            <Swiper
              ref={swiperRef}
              pagination={{ clickable: true }}
              autoplay={{ delay: 5000 }}
              modules={[Autoplay, Pagination]}
              onClick={() => {
                const autoplay = (swiperRef as any)?.current?.swiper?.autoplay;
                if (autoplay.running) {
                  autoplay.stop();
                } else {
                  autoplay.start();
                }
              }}
            >
              {sliderItems?.map((item: any) => {
                return (
                  <SwiperSlide key={item.ID}>
                    <SliderContainer>
                      <div>
                        <Tile
                          theme={theme}
                          width="27.6rem"
                          aspectRatio="3:4"
                          textPosition="none"
                          imageSrc={item.imageSrc}
                          onClick={() => undefined}
                          noImageText={t('common:error_page.no_image.text')}
                        />
                      </div>
                      <SliderContent>
                        <h1>{item?.title}</h1>
                        <p>{item?.description}</p>
                      </SliderContent>
                    </SliderContainer>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </OnboardingRight>
        </OnboardingContent>
        <ActionBottomContainer>
          {/* TODO: add role to components in design library, and remove div here */}
          <div role="skip-button">
            <Button
              buttonType="transparent"
              buttonMode="dark"
              width="27.2rem"
              handleClick={onContinue}
            >
              {t('onboarding:skipButtonText')}
            </Button>
          </div>
          <Button buttonMode="dark" width="27.2rem" handleClick={onContinueToOnboardingSurvey}>
            {t('onboarding:continueButtonText')}
          </Button>
        </ActionBottomContainer>
      </OnboardingOverlay>
    </Container>
  );
};

export default Onboarding;
