import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { TileSwiper } from '@aerq/aerq-design-system';
import { useTheme } from 'styled-components';

import { useGetSwimlaneContents, useTileClickHandler } from 'globals/hooks';
import { AerenaContent } from 'globals/cms-helper/types';
import { SectionMeta } from 'globals/types/meta';

export const TileSwiperWrapper: React.FC<{ section: AerenaContent }> = ({ section }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  // sectionMeta contains Tile container properties
  const sectionMeta = section?.metadata as SectionMeta;

  const { tileItems } = useGetSwimlaneContents(section);

  const tileClickHandler = useTileClickHandler(section);

  const placeholderItems = [{ ID: useId() }, { ID: useId() }, { ID: useId() }];

  const pathname = window?.location?.pathname;
  const isListenPage = pathname === '/listen';

  return (
    <>
      {!tileItems ? (
        <TileSwiper
          isLoading
          tiles={placeholderItems}
          xPadding="0rem"
          theme={theme}
          ratio={sectionMeta?.tileRatio ?? (isListenPage ? '1:1' : '16:9')}
          showTitle={sectionMeta?.showTitle ?? true}
          showCaption={sectionMeta?.showCaption ?? isListenPage}
          showDescription={sectionMeta?.showDescription ?? false}
          showSub={sectionMeta?.showSub ?? false}
          showTag={sectionMeta?.showTag ?? false}
          onTileClick={() => null}
          slidesPerView={sectionMeta?.slidesPerView ?? (isListenPage ? 2.74 : 1.7)}
          noImageText={t('common:error_page.no_image.text')}
        />
      ) : (
        tileItems?.length > 0 && (
          <TileSwiper
            theme={theme}
            tiles={tileItems}
            ratio={sectionMeta?.tileRatio}
            xPadding="0rem"
            showTitle={sectionMeta?.showTitle}
            showCaption={sectionMeta?.showCaption}
            showDescription={sectionMeta?.showDescription}
            showSub={sectionMeta?.showSub}
            showTag={sectionMeta?.showTag}
            onTileClick={tileClickHandler}
            slidesPerView={sectionMeta?.slidesPerView}
            noImageText={t('common:error_page.no_image.text')}
          />
        )
      )}
    </>
  );
};
