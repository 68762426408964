import axios from 'axios';

import { videoPath } from 'globals/cms-helper/movieHelper';
import { AerenaContent, AerenaContentType } from 'globals/cms-helper/types';
import {
  TCurrentSeason,
  TEpisodeInfo,
  TLastWatchedEpisodeInSeries,
  TSelectedContent
} from 'globals/types/playable';
import { TEpisodeTileItem } from 'globals/types/tile';

export const getMovieCurrentPosition = async (id: string, seatNumber: string) => {
  try {
    const resp = await axios.get(`/v1/flight-context/watchProgress/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Seat-ID': seatNumber
      }
    });
    return Number(resp?.data);
  } catch (e) {
    return null;
  }
};

export const getAllWatchProgress = async (seatNumber: string) => {
  try {
    const resp = await axios.get(`/v1/flight-context/watchProgress/`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Seat-ID': seatNumber
      }
    });
    return resp?.data as Record<string, string>;
  } catch (e) {
    return null;
  }
};

export const getLastWatchedEpisode = (
  seriesId: string,
  lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries
) => {
  return lastWatchedEpisodeInSeries?.find((content) => content?.seriesId === seriesId)?.episode;
};

export const updateSelectedEpisodeInfoInStore = (
  lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries,
  setLastWatchedEpisodeInSeries: (val: TLastWatchedEpisodeInSeries) => void,
  content: AerenaContent,
  episodeInfo: TSelectedContent | TEpisodeTileItem,
  currentPos: number
) => {
  // Check if the series with the given seriesId exists in watchedEpisodesInSeries
  const seriesToUpdateIndex = lastWatchedEpisodeInSeries.findIndex(
    (series) => series?.seriesId === content?.ID
  );

  // If series with seriesId exists
  if (seriesToUpdateIndex !== -1) {
    lastWatchedEpisodeInSeries[seriesToUpdateIndex].episode = {
      episodeId: episodeInfo?.ID,
      seasonId: episodeInfo?.seasonId,
      title: episodeInfo?.title,
      url: episodeInfo?.url,
      runtimeInMin: episodeInfo?.runtimeInMin,
      currentPosInSec: currentPos || 0,
      episodeNum: episodeInfo?.episodeNum,
      seasonNum: episodeInfo?.seasonNum
    };
  } else {
    // If series with seriesId doesn't exist, create a new object with the series and episode
    lastWatchedEpisodeInSeries.push({
      seriesId: content?.ID,
      episode: {
        episodeId: episodeInfo?.ID,
        seasonId: episodeInfo?.seasonId,
        title: episodeInfo?.title,
        url: episodeInfo?.url,
        runtimeInMin: episodeInfo?.runtimeInMin,
        currentPosInSec: currentPos || 0,
        episodeNum: episodeInfo?.episodeNum,
        seasonNum: episodeInfo?.seasonNum
      }
    });
  }
  setLastWatchedEpisodeInSeries([...lastWatchedEpisodeInSeries]);
};

export const getEpisodeCurrPosInSec = (
  seriesId: string,
  episode: AerenaContent | TEpisodeTileItem,
  lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries,
  allWatchProgress: Record<string, string>
) => {
  let currPos: number;
  // first search in lastWatchedEpisode store
  currPos = lastWatchedEpisodeInSeries?.find(
    (series) => series?.seriesId === seriesId && series?.episode?.episodeId === episode?.ID
  )?.episode?.currentPosInSec;

  // if not found, then search in allWatchProgress
  if (!currPos) {
    currPos = Number(allWatchProgress?.[episode?.ID]) || 0;
  }
  return currPos || 0;
};

export const calcRemTimeInMin = (runtime: number, pos: number) => {
  // calculate when pos > O. Else, return runtime which means that content was never played
  if (pos > 0) {
    const totalRuntimeInSec = runtime * 60;

    const remainingTimeInMin = (totalRuntimeInSec - pos) / 60;

    return Math.ceil(remainingTimeInMin);
  }
  return runtime;
};

export const calcRemTimeInSec = (runtime: number, pos: number) => {
  // calculate when pos > O. Else, return runtime which means that content was never played
  if (pos > 0) {
    const totalRuntimeInSec = runtime * 60;

    const remainingTimeInSec = totalRuntimeInSec - pos;

    return Math.ceil(remainingTimeInSec);
  }
  return runtime;
};

export const calcWatchedTimeInPercent = (totalRuntime: number, elapsedTime: number) => {
  return Number(((elapsedTime / totalRuntime) * 100).toFixed(2));
};

export const isContentAlmostWatched = (runtime: number, pos: number) => {
  const diffInSecs = calcRemTimeInSec(runtime, pos);
  return diffInSecs <= 5;
};

export const setPosByElapsedTime = (runtime: number, pos: number) => {
  const isWatched = isContentAlmostWatched(runtime, pos);
  if (isWatched) {
    return 0;
  }
  return pos || 0;
};

export const setRemTimeByElapsedTime = (runtime: number, pos: number) => {
  const isWatched = isContentAlmostWatched(runtime, pos);

  if (isWatched) {
    return 0;
  } else if (pos > 0) {
    return calcRemTimeInMin(runtime, pos);
  }
  return 0;
};

export const getContentRuntime = (
  contentType: AerenaContentType,
  playableMovie: TSelectedContent,
  playableEpisode: TSelectedContent
) => {
  return contentType === 'aerena_movie'
    ? playableMovie?.runtimeInMin
    : playableEpisode?.runtimeInMin;
};

export const getContentRemainingTime = (
  contentType: AerenaContentType,
  playableMovie: TSelectedContent,
  playableEpisode: TSelectedContent
) => {
  return contentType === 'aerena_movie'
    ? playableMovie?.remainingTimeInMin
    : playableEpisode?.remainingTimeInMin;
};

export const getContentElapsedTime = (
  contentType: AerenaContentType,
  playableMovie: TSelectedContent,
  playableEpisode: TSelectedContent
) => {
  return contentType === 'aerena_movie'
    ? playableMovie?.currentPosInSec
    : playableEpisode?.currentPosInSec;
};

export const updateEpisodeProgress = (episodeItems: TEpisodeTileItem[], data: TEpisodeInfo) => {
  const updatedItems = [...episodeItems];
  const watchedIdx = updatedItems.findIndex((episode) => episode?.ID === data?.episodeId);

  if (data.currentPosInSec && episodeItems?.length) {
    updatedItems[watchedIdx].currentPosInSec = data.currentPosInSec || 0;
    updatedItems[watchedIdx].progressWidth =
      data.currentPosInSec > 0
        ? calcWatchedTimeInPercent(
            updatedItems[watchedIdx]?.runtimeInMin,
            data.currentPosInSec / 60
          )
        : 0;
  }
  return updatedItems;
};

export const handleAlmostCompletedEpisode = (
  episodeItems: TEpisodeTileItem[],
  watchedIdx: number,
  lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries,
  setLastWatchedEpisodeInSeries: (lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries) => void,
  content: AerenaContent
) => {
  const nextPlayableEpisode = episodeItems?.[watchedIdx + 1] || episodeItems?.[0];

  if (nextPlayableEpisode) {
    const areCurrentAndNextEpisodeSame = nextPlayableEpisode.ID === episodeItems?.[0]?.ID;
    const currentPosInSec = areCurrentAndNextEpisodeSame
      ? 0
      : setPosByElapsedTime(nextPlayableEpisode.runtimeInMin, nextPlayableEpisode.currentPosInSec);

    updateSelectedEpisodeInfoInStore(
      lastWatchedEpisodeInSeries,
      setLastWatchedEpisodeInSeries,
      content,
      nextPlayableEpisode,
      currentPosInSec
    );
    return true;
  }
  return false;
};

export const updatePlayableMovie = async (
  contentType: AerenaContentType,
  content: AerenaContent,
  seatNumber: string,
  setPlayableMovie: React.Dispatch<React.SetStateAction<TSelectedContent>>
) => {
  if (contentType === 'aerena_movie') {
    const pos = await getMovieCurrentPosition(content?.ID, seatNumber);
    setPlayableMovie({
      ID: content?.ID,
      title: (content?.metadata as any)?.title,
      url: videoPath(content),
      runtimeInMin: (content?.metadata as any)?.runtime,
      currentPosInSec: setPosByElapsedTime((content?.metadata as any)?.runtime, pos),
      remainingTimeInMin: setRemTimeByElapsedTime((content?.metadata as any)?.runtime, pos)
    });
  }
};

export const updatePlayableTVShow = (
  contentType: AerenaContentType,
  content: AerenaContent,
  season: TCurrentSeason,
  episodes: AerenaContent[],
  lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries,
  episodeTileItems: TEpisodeTileItem[],
  setEpisodeTileItems: React.Dispatch<React.SetStateAction<TEpisodeTileItem[]>>,
  setPlayableEpisode: React.Dispatch<React.SetStateAction<TSelectedContent>>,
  setLastWatchedEpisodeInSeries: (lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries) => void
) => {
  if (contentType === 'aerena_tv_show') {
    const data = getLastWatchedEpisode(content?.ID, lastWatchedEpisodeInSeries);
    if (data) {
      const isLastWatchedEpisodeAlmostCompleted = isContentAlmostWatched(
        data.runtimeInMin,
        data.currentPosInSec
      );
      let episodeItems = [...episodeTileItems];
      episodeItems = updateEpisodeProgress(episodeItems, data);
      setEpisodeTileItems(episodeItems);

      if (isLastWatchedEpisodeAlmostCompleted) {
        const watchedIdx = episodeItems?.findIndex((episode) => episode?.ID === data?.episodeId);
        if (
          handleAlmostCompletedEpisode(
            episodeItems,
            watchedIdx,
            lastWatchedEpisodeInSeries,
            setLastWatchedEpisodeInSeries,
            content
          )
        ) {
          return;
        }
      }

      setPlayableEpisode({
        ID: data.episodeId,
        seasonId: data.seasonId,
        seasonNum: data.seasonNum,
        episodeNum: data.episodeNum,
        title: data.title,
        url: data.url,
        runtimeInMin: data.runtimeInMin,
        currentPosInSec: data.currentPosInSec,
        remainingTimeInMin: setRemTimeByElapsedTime(data.runtimeInMin, data.currentPosInSec)
      });
    } else {
      setPlayableEpisode({
        ID: episodes?.[0]?.ID,
        seasonId: season?.seasonId,
        seasonNum: season?.seasonNumber,
        episodeNum: (episodes?.[0]?.metadata as any)?.episode?.[0],
        title: (episodes?.[0]?.metadata as any)?.title,
        url: videoPath(episodes?.[0]),
        runtimeInMin: (episodes?.[0]?.metadata as any)?.runtime,
        currentPosInSec: 0,
        remainingTimeInMin: 0
      });
    }
  }
};
