import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TileScroller } from '@aerq/aerq-design-system';
import { useShallow } from 'zustand/react/shallow';
import { useTheme } from 'styled-components';

import { fetchSectionFilter, getBackgroundImage } from 'globals/cms-helper/layoutApi';
import { assetsPath } from 'globals/api';
import { AerenaContent } from 'globals/cms-helper/types';
import { useGlobalStore } from 'globals/store';
import { useGetSwimlaneContents, useTileClickHandler } from 'globals/hooks';
import { useNavigationEventStore } from 'globals/store/navigationevent.store';
import { getCurrentLangBundle } from 'globals/cms-helper/movieHelper';
import { pageXMargin } from 'globals/styles';
import { SectionMeta } from 'globals/types/meta';

import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';
import { WatchOption } from 'views/watch/components/watchOptionsContainer';

export const TileScrollerWrapper: React.FC<{ section: AerenaContent }> = ({ section }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation();

  const backgroundImage = section?.children?.['backgroundImage']
    ? assetsPath + getBackgroundImage(section)
    : null;

  const {
    setWatchMainOptions,
    setWatchSecondaryOptions,
    watchMainOptions,
    watchSecondaryOptions,
    selectedLanguage,
    setResetWatchOptions
  } = useGlobalStore(
    useShallow((state) => ({
      setWatchMainOptions: state.setWatchMainOptions,
      setWatchSecondaryOptions: state.setWatchSecondaryOptions,
      watchMainOptions: state.watchMainOptions,
      watchSecondaryOptions: state.watchSecondaryOptions,
      selectedLanguage: state.selectedLanguage,
      setResetWatchOptions: state.setResetWatchOptions
    }))
  );

  const setNavigationEventOriginPage = useNavigationEventStore(
    (state) => state.setNavigationEventOriginPage
  );

  const { tileItems } = useGetSwimlaneContents(section);

  const sectionMeta = section?.metadata as SectionMeta;
  const enableText = sectionMeta?.imageType !== 'poster';

  const tileClickHandler = useTileClickHandler(section);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  };

  const seeAllClickHandler = () => {
    scrollToTop();
    const sectionFilterData = fetchSectionFilter(section);

    const handleSectionMetaWithUrl = () => {
      const url = sectionMeta.url?.join('/');
      const state = buildNavigationEventOriginPageState();

      setNavigationEventOriginPage(state);

      const isBrowseAllSelected = isBrowseAllOptionSelected();
      updateWatchOptions(isBrowseAllSelected);
      navigateToUrl(url);
      setResetWatchOptions(false);
    };

    const buildNavigationEventOriginPageState = () => ({
      type: sectionMeta?.categories?.[0],
      languageSelected: selectedLanguage.code,
      clickedElement: {
        id: section?.ID,
        name: sectionMeta?.title,
        type: sectionMeta?.type,
        rank: sectionMeta?.rank + 1,
        clickedElement: {
          id: sectionMeta?.seeAllText,
          name: sectionMeta?.seeAllText,
          type: 'See All Tile',
          rank: 0
        }
      }
    });

    const isBrowseAllOptionSelected = () =>
      sectionFilterData.contentTypes.includes('aerena_movie') &&
      sectionFilterData.contentTypes.includes('aerena_tv_show');

    const updateOptions = (options: WatchOption[], values: string[]) =>
      options.map((option) => ({ ...option, selected: values.includes(option.value) }));

    const updateWatchOptions = (isBrowseAllSelected: boolean) => {
      if (watchMainOptions?.length > 0 && watchSecondaryOptions?.length > 0) {
        updateMainOptions(isBrowseAllSelected);
        updateSecondaryOptions();
      } else {
        setDefaultWatchOptions(isBrowseAllSelected);
      }
    };

    const updateMainOptions = (isBrowseAllSelected: boolean) => {
      const mainOptions = watchMainOptions.map((option) => ({
        ...option,
        selected: isBrowseAllSelected
          ? option.value.includes('browse_all')
          : sectionFilterData.contentTypes.includes(option.value)
      }));
      setWatchMainOptions(mainOptions);
    };

    const updateSecondaryOptions = () => {
      const secondaryOptions = updateOptions(watchSecondaryOptions, sectionFilterData.genres);
      setWatchSecondaryOptions(secondaryOptions);
    };
    const setDefaultWatchOptions = (isBrowseAllSelected: boolean) => {
      const mainOptions = buildMainOptions(isBrowseAllSelected);
      const secondaryOptions = buildSecondaryOptions();
      setWatchMainOptions(mainOptions);
      setWatchSecondaryOptions(secondaryOptions);
    };

    const buildMainOptions = (isBrowseAllSelected: boolean) => {
      const mainOptionsBundle = getCurrentLangBundle(selectedLanguage.code, 'watchMainOptions');
      return Object.keys(mainOptionsBundle || {}).map((o) => ({
        title: mainOptionsBundle?.[o],
        value: o,
        selected: isBrowseAllSelected
          ? o === 'browse_all'
          : sectionFilterData?.contentTypes?.includes(o)
      }));
    };

    const buildSecondaryOptions = () => {
      const secondaryOptionsBundle = getCurrentLangBundle(
        selectedLanguage.code,
        'watchSecondaryOptions'
      );
      return Object.keys(secondaryOptionsBundle || {}).map((o) => ({
        title: secondaryOptionsBundle?.[o],
        value: o,
        selected: sectionFilterData?.genres?.includes(o)
      }));
    };

    const handleNoSectionMetaUrl = () => {
      navigateToDefaultSeeAllPage();
    };

    const navigateToUrl = (url: string) => {
      navigate(url);
    };

    const navigateToDefaultSeeAllPage = () => {
      navigate(`/see-all/${section?.ID}`, {
        state: { section }
      });
    };

    if (sectionMeta.url?.length > 0) {
      handleSectionMetaWithUrl();
    } else {
      handleNoSectionMetaUrl();
    }
  };

  return (
    <>
      {!tileItems ? (
        <PlaceHolderWrapper
          type="TileScroller"
          width={sectionMeta?.tileWidth}
          ratio={sectionMeta?.tileRatio}
          showPlaceholderTitle={true}
          noOfTile={9}
        />
      ) : (
        tileItems?.length > 0 && (
          <TileScroller
            theme={theme}
            title={sectionMeta?.title}
            textPosition={sectionMeta?.textPosition}
            showTitle={true}
            showCaption={sectionMeta?.showCaption}
            showDescription={sectionMeta?.showDescription}
            showSub={sectionMeta?.showSub}
            showTag={enableText && sectionMeta?.showTag}
            ratio={sectionMeta?.tileRatio}
            xPadding={pageXMargin}
            width={sectionMeta?.tileWidth}
            tiles={tileItems}
            onTileClick={tileClickHandler}
            onSeeAllClick={seeAllClickHandler}
            hasSeeAll={sectionMeta?.hasSeeAll}
            seeAllMaxLimit={sectionMeta?.seeAllMaxLimit}
            description={sectionMeta?.description}
            seeAllText={sectionMeta?.seeAllText}
            backgroundImage={backgroundImage}
            noImageText={t('common:error_page.no_image.text')}
          />
        )
      )}
    </>
  );
};
