import { useEffect, useState } from 'react';

import { getEnvValue } from 'utils/useConfig';
import { socketPED } from 'utils/socket';

export const usePedStatusListener = (
  setIsPedPaired: React.Dispatch<React.SetStateAction<boolean>>,
  seatNumber: string
) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (getEnvValue('REACT_APP_PED_SOCKET_URL') && seatNumber) {
      const socketPedStatus = socketPED(seatNumber);
      socketPedStatus.connect();
      setSocket(socketPedStatus);

      return () => {
        socketPedStatus.disconnect();
      };
    }
  }, [seatNumber]);

  useEffect(() => {
    const onConnectionStatusChange = (data: Record<string, any>) => {
      if (data) {
        setIsPedPaired(data?.isConnected);
      }
    };
    if (socket) {
      socket.on('connection-status-change', onConnectionStatusChange);
    }

    return () => {
      if (socket) {
        socket.off('connection-status-change', onConnectionStatusChange);
      }
    };
  }, [socket, setIsPedPaired]);
};
