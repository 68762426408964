import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PreRollAdPlayer, Tile } from '@aerq/aerq-design-system';
import { useShallow } from 'zustand/react/shallow';
import { useTheme } from 'styled-components';

import { WatchDetailProps } from 'globals/types/watch';
import { getContentMetaData, movieTrailerImages } from 'globals/cms-helper/movieHelper';
import { useFlightDataStore, useGlobalStore } from 'globals/store';

import HeroHeader from '../../components/heroHeader';
import TvShowSection from './tvShowSection/TvShowSection';
import styles from './watchDetails.module.css';
import {
  WatchSectionHeaderStyled,
  WatchRelatedContent,
  WatchTrailerContent,
  WatchTrailers,
  WatchDetailsAbout,
  WatchDetailsAboutHeader,
  WatchDetailsAboutItem,
  WatchDetailsLanguages,
  WatchDetailsContainer,
  WatchDetailsAboutContent,
  Divider
} from './watchDetails.styled';

import { formatMinuteRuntimeToInfo } from 'utils/runtimeFormat';
import { pageXMargin, Spacer } from 'globals/styles';
import { sdkInstance } from 'globals/api';
import { usePlayVideo } from 'globals/hooks/usePlayVideo';
import { usePassengerAnnouncementListener } from 'globals/hooks/usePassengerAnnouncementListener';
import { TEpisodeTileItem } from 'globals/types/tile';
import { TPlayableVideo } from 'globals/types/playable';

import ExpandableText from 'components/ExpandableText/ExpandableText';
import { BillboardAd } from 'components/BillboardAd';
import { FavoritesModal } from 'components/FavoritesModal';

export const WatchDetails: React.FC<WatchDetailProps> = ({
  content,
  seasons,
  initialEpisodes,
  currentSeason,
  contentType,
  related,
  advertisement
}) => {
  const [episodeTileItems, setEpisodeTileItems] = useState<TEpisodeTileItem[]>([]);
  const [preRollVideo, setPreRollVideo] = useState<boolean>(false);
  const [preRollVideoURL, setPreRollVideoURL] = useState<string>(null);
  const [preRollPlayer, setPreRollPlayer] = useState(null);
  const [playableVideo, setPlayableVideo] = useState<TPlayableVideo>(null);

  usePassengerAnnouncementListener();

  const { aerenaStudioPreview, selectedLanguage } = useGlobalStore(
    useShallow((state) => ({
      aerenaStudioPreview: state.aerenaStudioPreview,
      selectedLanguage: state.selectedLanguage
    }))
  );

  const { previewID } = aerenaStudioPreview;

  const { flightData } = useFlightDataStore(
    useShallow((state) => ({
      flightData: state.flightData
    }))
  );

  const trailerImages = movieTrailerImages(content);
  const meta = getContentMetaData(content);

  const theme = useTheme();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const metaDict = {
    key: t('watch:details.information.title'),
    value: [
      contentType === 'aerena_movie'
        ? { key: t('watch:details.starring.title'), value: meta?.cast }
        : {},
      contentType === 'aerena_movie'
        ? { key: t('watch:details.directors.title'), value: meta?.directors }
        : {},
      {
        key: t('watch:details.run_time.title'),
        value:
          contentType === 'aerena_movie'
            ? formatMinuteRuntimeToInfo(meta?.runtime || 0)
            : meta?.numberOfSeasons
      },
      { key: t('watch:details.release.title'), value: meta?.releaseDate || meta?.releaseYear },
      {
        key: t('watch:details.rated.title'),
        value: meta?.ratings ? meta?.ratings?.join(', ') : ''
      },
      {
        key: t('watch:details.region_of_origin.title'),
        value: meta?.origin ? meta?.origin?.join(', ') : ''
      },
      {
        key: t('watch:details.original_audio.title'),
        value: meta?.originalLanguage ? t(`languages:${meta?.originalLanguage}`) : ''
      }
    ].filter((o) => Object.keys(o).length > 0)
  };

  const languageDict = {
    key: t('watch:details.languages.title'),
    value: [
      {
        key: t('watch:details.audio.title'),
        value:
          meta?.audios?.length > 0 ? meta?.audios?.map((a) => t('languages:' + a))?.join(', ') : ''
      },
      {
        key: t('watch:details.subtitles.title'),
        value:
          meta?.subtitles?.length > 0
            ? meta?.subtitles?.map((a) => t('languages:' + a))?.join(', ')
            : ''
      }
    ]
  };

  const adContent = advertisement?.children?.filter?.[0]?.children?.contents?.[0];

  const onHandlePlayerReady = useCallback((player: any) => {
    setPreRollPlayer(player);
  }, []);

  usePlayVideo(
    preRollVideo,
    preRollVideoURL,
    playableVideo,
    setPlayableVideo,
    selectedLanguage.code
  );

  useEffect(() => {
    if (preRollPlayer) {
      if (flightData?.displayPA) {
        preRollPlayer?.pause();
      } else {
        preRollPlayer?.play();
      }
    }
  }, [flightData?.displayPA, preRollPlayer]);

  const handlePlayerUpdate = useCallback(() => {
    setPreRollVideo(false);
    setPreRollVideoURL('NA');
    setPreRollPlayer(null);
    sdkInstance?.setContext('landing');
  }, []);

  return (
    <>
      {preRollVideo && preRollVideoURL && (
        <PreRollAdPlayer
          className={styles.pre_roll_ad_styles}
          url={preRollVideoURL}
          onError={handlePlayerUpdate}
          onComplete={handlePlayerUpdate}
          onPlayerReady={onHandlePlayerReady}
        />
      )}
      {!preRollVideo && <FavoritesModal favoriteType="watch" />}
      <HeroHeader
        content={content}
        info={metaDict}
        episodes={initialEpisodes}
        episodeTileItems={episodeTileItems}
        setEpisodeTileItems={setEpisodeTileItems}
        season={currentSeason}
        setPreRollVideo={setPreRollVideo}
        setPreRollVideoURL={setPreRollVideoURL}
        setPlayableVideo={setPlayableVideo}
      />
      {contentType === 'aerena_tv_show' && (
        <TvShowSection
          xPadding={pageXMargin}
          content={content}
          seasons={seasons}
          episodes={initialEpisodes}
          episodeTileItems={episodeTileItems}
          setEpisodeTileItems={setEpisodeTileItems}
          originalLanguageCode={meta?.originalLanguage}
          setPreRollVideo={setPreRollVideo}
          setPreRollVideoURL={setPreRollVideoURL}
          setPlayableVideo={setPlayableVideo}
        />
      )}
      {trailerImages?.length > 0 && (
        <WatchTrailers>
          <WatchSectionHeaderStyled>{t('watch:details.trailers.title')}</WatchSectionHeaderStyled>
          <WatchTrailerContent>
            {trailerImages?.map((trailer) => (
              <div style={{ position: 'relative' }} key={trailer?.id}>
                <Tile
                  theme={theme}
                  width={['25rem', '40rem', '88.2rem']}
                  aspectRatio="16:9"
                  textPosition={'belowImageBordered'}
                  imageSrc={trailer?.imgSrc}
                  title={trailer?.text}
                />
              </div>
            ))}
          </WatchTrailerContent>
        </WatchTrailers>
      )}

      {related?.length > 0 && (
        <>
          <WatchSectionHeaderStyled>{t('watch:details.related.title')}</WatchSectionHeaderStyled>
          <WatchRelatedContent>
            {related?.map((related) => (
              <Tile
                theme={theme}
                key={related?.id}
                width={['16rem', '25rem', '40rem']}
                aspectRatio="3:4"
                title={related?.text}
                textPosition={'belowImageBordered'}
                imageSrc={related?.imgSrc}
                onClick={() => (previewID ? null : navigate(`/watch/${related?.id}`))}
              />
            ))}
          </WatchRelatedContent>
        </>
      )}
      {adContent && <BillboardAd section={adContent} />}
      {metaDict?.value?.some((it) => it?.value) && (
        <WatchDetailsContainer>
          <Divider />
          <WatchDetailsAbout data-testid="info-section-1">
            <WatchDetailsAboutHeader id="information">{metaDict?.key}</WatchDetailsAboutHeader>
            {metaDict?.key && (
              <WatchDetailsAboutContent>
                {metaDict?.value?.map(
                  (item) =>
                    item?.value && (
                      <WatchDetailsAboutItem key={item?.key}>
                        <div>{item?.key}</div>
                        <ExpandableText
                          text={item?.value}
                          isTextDark={true}
                          seeMoreLabel={t('watch:details.see_more')}
                          seeLessLabel={t('watch:details.see_less')}
                        />
                      </WatchDetailsAboutItem>
                    )
                )}
              </WatchDetailsAboutContent>
            )}
          </WatchDetailsAbout>
        </WatchDetailsContainer>
      )}
      {languageDict?.value?.some((it) => it?.value) && (
        <WatchDetailsContainer>
          <Divider />
          <WatchDetailsLanguages data-testid="info-section-2">
            <WatchDetailsAboutHeader>{languageDict?.key}</WatchDetailsAboutHeader>
            {languageDict?.key && (
              <WatchDetailsAboutContent>
                {languageDict?.value?.map(
                  (item) =>
                    item?.value && (
                      <WatchDetailsAboutItem key={item?.key}>
                        <div>{item?.key}</div>
                        <ExpandableText
                          text={item?.value}
                          isTextDark={true}
                          seeMoreLabel={t('watch:details.see_more')}
                          seeLessLabel={t('watch:details.see_less')}
                        />
                      </WatchDetailsAboutItem>
                    )
                )}
              </WatchDetailsAboutContent>
            )}
          </WatchDetailsLanguages>
        </WatchDetailsContainer>
      )}
      {!preRollVideo && <Spacer />}
    </>
  );
};
