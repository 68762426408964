import { AerenaContent } from 'globals/cms-helper/types';
import { SectionMeta } from 'globals/types/meta';

export const formatPageContentForLogEvent = (contents: AerenaContent[]) => {
  const content = contents?.[0];
  const contentMeta = content?.metadata as Record<string, any>;

  return content?.ID
    ? [
        {
          id: content?.ID,
          name: contentMeta?.title as string,
          rank: contentMeta?.rank + 1,
          type: content?.contentTypeID
        }
      ]
    : [];
};

export const logWatchGridEvents = (contents: AerenaContent[]) =>
  contents?.map((content: AerenaContent, index: number) => {
    const contentMeta = content?.metadata as SectionMeta;
    return {
      id: content?.ID,
      name: contentMeta?.title,
      rank: index + 1,
      type: content?.contentTypeID
    };
  });
