import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@aerq/aerq-design-system';
import { useTheme } from 'styled-components';
import { debounce } from 'lodash';

import { BackToTopAction } from './BackToTop.styled';

type BackToTopProps = {
  threshold?: number;
};

const BackToTop: React.FC<BackToTopProps> = ({ threshold = 1552 }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const theme = useTheme();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleVisible = useCallback(
    debounce(() => {
      const scrolled = document.documentElement.scrollTop;
      setVisible(scrolled > threshold);
    }, 100),
    []
  );

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);

    return () => window.removeEventListener('scroll', toggleVisible);
  }, [toggleVisible]);

  return (
    visible && (
      <BackToTopAction data-testid="backToTopContainer">
        <Button
          icon="BackToTop"
          buttonType="outlined"
          handleClick={scrollToTop}
          bgColor={theme?.colors?.primaryWhite}
        >
          {t('viewAll:backToTopButton')}
        </Button>
      </BackToTopAction>
    )
  );
};

export default BackToTop;
