import axios from 'axios';

import { TAudioPlayerQueue, TAudioPlayerStatus } from 'globals/types/audio';
import { getEnvValue } from 'utils/useConfig';

const REACT_APP_AUDIO_URL = getEnvValue('REACT_APP_AUDIO_URL');

export const getPlayerStatus = () => {
  try {
    return axios.get<TAudioPlayerStatus>(`${REACT_APP_AUDIO_URL}/v1/audio-player/status`);
  } catch (e) {
    console.error('Failed to get the status of the audio player', e);
  }
};

export const getPlayerQueue = () => {
  try {
    return axios.get<TAudioPlayerQueue[]>(`${REACT_APP_AUDIO_URL}/v1/audio-player/queue`);
  } catch (e) {
    console.error('Failed to get the queue of the audio player', e);
  }
};

export const appendPlayerQueue = (uris: string[]) => {
  try {
    return axios.put(`${REACT_APP_AUDIO_URL}/v1/audio-player/queue`, uris, {
      headers: { 'Content-Type': 'application/json' }
    });
  } catch (e) {
    console.error('Failed to append the queue of the audio player', e);
  }
};

export const clearPlayerQueue = () => {
  try {
    return axios.delete(`${REACT_APP_AUDIO_URL}/v1/audio-player/queue`);
  } catch (e) {
    console.error('Failed to clear the queue of the audio player', e);
  }
};

export const getPlayerQueueByTrackId = (trackId: string) => {
  try {
    return axios.get<TAudioPlayerQueue>(`${REACT_APP_AUDIO_URL}/v1/audio-player/queue/${trackId}`);
  } catch (e) {
    console.error('Failed to get the queue of the audio player by trackId', e);
  }
};

export const clearPlayerQueueByTrackId = (trackId: string) => {
  try {
    return axios.delete(`${REACT_APP_AUDIO_URL}/v1/audio-player/queue/${trackId}`);
  } catch (e) {
    console.error('Failed to clear the queue of the audio player by trackId', e);
  }
};

export const playEpisode = (trackIndex?: number) => {
  try {
    let url = `${REACT_APP_AUDIO_URL}/v1/audio-player/play`;

    if (typeof trackIndex === 'number')
      url = `${REACT_APP_AUDIO_URL}/v1/audio-player/play/${trackIndex}`;

    return axios.post(url);
  } catch (e) {
    console.error('Failed to play the episode', e);
  }
};

export const pauseEpisode = () => {
  try {
    return axios.post(`${REACT_APP_AUDIO_URL}/v1/audio-player/pause`);
  } catch (e) {
    console.error('Failed to pause the episode', e);
  }
};

export const playNextEpisode = () => {
  try {
    return axios.post(`${REACT_APP_AUDIO_URL}/v1/audio-player/next`);
  } catch (e) {
    console.error('Failed to play the next episode', e);
  }
};

export const playPreviousEpisode = () => {
  try {
    return axios.post(`${REACT_APP_AUDIO_URL}/v1/audio-player/previous`);
  } catch (e) {
    console.error('Failed to play the previous episode', e);
  }
};

export const stopEpisode = () => {
  try {
    return axios.post(`${REACT_APP_AUDIO_URL}/v1/audio-player/stop`);
  } catch (e) {
    console.error('Failed to stop the episode', e);
  }
};

export const shuffleEpisode = (mode: boolean) => {
  try {
    return axios.post(
      `${REACT_APP_AUDIO_URL}/v1/audio-player/shuffle`,
      { mode },
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (e) {
    console.error('Failed to shuffle the episode', e);
  }
};

export const seekEpisode = (position: number) => {
  try {
    return axios.post(
      `${REACT_APP_AUDIO_URL}/v1/audio-player/seek`,
      { position },
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (e) {
    console.error('Failed to seek the episode', e);
  }
};
