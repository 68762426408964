import React from 'react';

import { HomePageFooter } from './ResetAudioSubtitles.styled';

import BackToTop from 'components/BackToTop/BackToTop';

const ResetAudioSubtitles: React.FC = () => (
  <HomePageFooter>
    <BackToTop />
  </HomePageFooter>
);

export default ResetAudioSubtitles;
