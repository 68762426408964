import React from 'react';
import { useTranslation } from 'react-i18next';

import { TileContainer } from '@aerq/aerq-design-system';
import { useTheme } from 'styled-components';

import { AerenaContent } from 'globals/cms-helper/types';
import { pageXMargin } from 'globals/styles';
import { useGetSwimlaneContents, useTileClickHandler } from 'globals/hooks';
import { SectionMeta } from 'globals/types/meta';

import { Container } from './TileContainerWrapper.styled';
import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';

export const TileContainerWrapper: React.FC<{ section: AerenaContent }> = ({ section }) => {
  // sectionMeta contains Tile container properties
  const sectionMeta = section?.metadata as SectionMeta;

  const { t } = useTranslation();

  const theme = useTheme();
  const enableText = sectionMeta?.imageType !== 'poster';

  const { tileItems } = useGetSwimlaneContents(section);

  const tileClickHandler = useTileClickHandler(section);

  return (
    <>
      {!tileItems ? (
        <PlaceHolderWrapper
          type="TileContainerAndGrid"
          ratio={sectionMeta?.tileRatio}
          showPlaceholderTitle={true}
        />
      ) : (
        tileItems?.length > 0 && (
          <Container xMargin={pageXMargin}>
            <TileContainer
              theme={theme}
              columns={sectionMeta?.columns}
              title={sectionMeta?.title}
              textPosition={sectionMeta?.textPosition}
              showTitle={sectionMeta?.showTitle}
              showCaption={sectionMeta?.showCaption}
              showDescription={sectionMeta?.showDescription}
              showSub={sectionMeta?.showSub}
              showTag={enableText && sectionMeta?.showTag}
              ratio={sectionMeta?.tileRatio}
              leftPadding={'4.8rem'}
              width={'100%'}
              tiles={tileItems}
              onTileClick={tileClickHandler}
              noImageText={t('common:error_page.no_image.text')}
            ></TileContainer>
          </Container>
        )
      )}
    </>
  );
};
